import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ata-nav-item',
  templateUrl: './nav-item.component.html',
  styleUrls: ['./nav-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavItemComponent {
  @Input() compact!: boolean;

  @Input() iconPath!: string;

  @Input() iconPosition: 'left' | 'right' = 'left';

  @Input() label!: string;
}
