import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AUTH_FEATURE_KEY } from './auth.constants';
import { State } from './auth.reducer';

export const getAuthState = createFeatureSelector<State>(AUTH_FEATURE_KEY);

export const getAuthJWT = createSelector(getAuthState, (state: State) => state.jwt);

export const getAuthOTP = createSelector(getAuthState, (state: State) => state.otp);

export const getAuthRequestOTP = createSelector(getAuthState, (state: State) => state.requestOTP);

export const getAuthRequestedOTP = createSelector(getAuthState, (state: State) => state.requestedOTP);

export const getAuthVerifyOTP = createSelector(getAuthState, (state: State) => state.verifyOTP);

export const getAuthVerifiedOTP = createSelector(getAuthState, (state: State) => state.verifiedOTP);

export const getAuthLogIn = createSelector(getAuthState, (state: State) => state.logIn);

export const getAuthLoggedIn = createSelector(getAuthState, (state: State) => state.loggedIn);

export const getAuthLogOut = createSelector(getAuthState, (state: State) => state.logOut);

export const getAuthLoggedOut = createSelector(getAuthState, (state: State) => state.loggedOut);

export const getAuthError = createSelector(getAuthState, (state: State) => state.error);
