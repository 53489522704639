/**
 * @deprecated
 */
export interface IWorkshop {
  id?: string;
  name: string;
  phoneNo: string;
  faxNo: string;
  email: string;
  address1: string;
  address2: string;
  postcode: string;
  city: string;
  state: string;
  category: string;
  status: string;
  latitude?: number;
  longitude?: number;
  distance?: string;
  distanceValue?: number;
}

/**
 * @deprecated
 */
export class Workshop implements IWorkshop {
  name: string;
  phoneNo: string;
  faxNo: string;
  email: string;
  address1: string;
  address2: string;
  postcode: string;
  city: string;
  state: string;
  category: string;
  status: string;
  latitude?: number;
  longitude?: number;
  distance?: string;
  distanceValue?: number;
}
