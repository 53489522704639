import { ECaseChannel } from '../enums/case-channel.enum';
import { ECaseLanguage } from '../enums/case-language.enum';
import { ECaseStatus, ECaseSubStatus } from '../enums/case-status.enum';
import { ICaseActivityLog } from './case-activity-log.interface';
import { ICaseAttachment } from './case-attachment.interface';
import { ICaseBilling } from './case-billing.interface';
import { ICaseCampaign } from './case-campaign.interface';
import { ICaseCarCondition } from './case-car-condition.interface';
import { ICaseCustomer } from './case-customer.interface';
import { ICaseEvent } from './case-event.interface';
import { ICaseNotification } from './case-notification.interface';
import { ICasePoliceStation } from './case-police-station.interface';
import { ICaseProvider } from './case-provider.interface';
import { ICaseService } from './case-service.interface';
import { ICaseSmsNotify } from './case-sms-notify.interface';
import { ICaseTowing } from './case-towing.interface';
import { ICaseTracking } from './case-tracking.interface';
import { ICaseUser } from './case-user.interface';
import { ICaseWorkshop } from './case-workshop.interface';
import { ICaseAssignee } from './case-assignee.interface';
import { ICaseAdditionalAddons } from './case-additional-addons.interface';
import { ICaseIssue } from './case-issue.interface';
import { ECaseVehicleType } from '../enums/case-vehicle-type.enum';

export interface ICase {
  // custom prop
  activityLog: Array<ICaseActivityLog>;
  additionalAddons: Array<ICaseAdditionalAddons>;
  addOnService: Array<string>;
  altPhoneNo: Array<string>;
  amount: number;
  assignee: ICaseAssignee;
  assignmentId: string;
  attachment: Array<ICaseAttachment>;
  billing: ICaseBilling;
  campaign: ICaseCampaign;
  carCondition: ICaseCarCondition;
  channel: ECaseChannel;
  createdAt: Date;
  customer: ICaseCustomer;
  debugErrorCount: number;
  description: string;
  distance: number;
  driverDistance: number;
  entity: string;
  event: Array<ICaseEvent>;
  geoLocation: string;
  hash?: string;
  id?: string;
  issue?: ICaseIssue;
  isTrackingMapShow: boolean;
  language: ECaseLanguage;
  location: string;
  locationRemark: string;
  destination: any;
  name: string;
  email: string;
  notifications: Array<ICaseNotification>;
  personInCharge: string;
  personInChargeInitials: string;
  phoneNo: string;
  policeStation: ICasePoliceStation;
  policyCoverage: string;
  provider: ICaseProvider;
  services: Array<ICaseService>;
  serviceType: string;
  smsNotify: ICaseSmsNotify;
  status: ECaseStatus;
  subStatus: ECaseSubStatus;
  ticketId: string;
  towing: ICaseTowing;
  tracking: ICaseTracking;
  users: Array<ICaseUser>;
  vehicleType: ECaseVehicleType;
  vehicleBrand: string;
  vehicleModel: string;
  vehicleDetail?: any;
  vehicleNo: string;
  vehicleYear: number;
  workshop: ICaseWorkshop;
  accidentDate: Date;
  automationLog: any;
  supportingDocument: any;
}
