import { IConfigFirebase } from '../interfaces/config-firebase.interface';
import { IConfig, IConfigFile } from '../interfaces/config.interface';
import { ConfigFirebase } from './config-firebase.model';

export class Config implements IConfig {
  local: boolean;
  sit: boolean;
  uat: boolean;
  prod: boolean;
  environment: string;
  authEndpoint: string;
  systemEndpoint: string;
  systemEndpointV2: string;
  commonEndpoint: string;
  socketEndpoint: string;
  cmsEndPoint: string;
  webEndpoint: string;
  smsTemplate: string;
  dashboardEndpoint: string;
  dashboardEndpointV2: string;
  providerEndpoint: string;
  minioEndpoint: string;
  firebase: IConfigFirebase;
  recaptchaSiteKey: string;
  loadPermission: boolean;

  constructor(config: Config) {
    this.local = config?.local;
    this.sit = config?.sit;
    this.uat = config?.uat;
    this.prod = config?.prod;
    this.environment = config?.environment;
    this.systemEndpoint = config?.systemEndpoint;
    this.systemEndpointV2 = config?.systemEndpointV2;
    this.commonEndpoint = config?.commonEndpoint;
    this.socketEndpoint = config?.socketEndpoint;
    this.cmsEndPoint = config?.cmsEndPoint;
    this.webEndpoint = config?.webEndpoint;
    this.smsTemplate = config?.smsTemplate;
    this.dashboardEndpoint = config?.dashboardEndpoint;
    this.dashboardEndpointV2 = config?.dashboardEndpointV2;
    this.providerEndpoint = config?.providerEndpoint;
    this.minioEndpoint = config?.minioEndpoint;
    this.authEndpoint = config?.authEndpoint;
    this.firebase = new ConfigFirebase(config?.firebase);
    this.recaptchaSiteKey = config?.recaptchaSiteKey;
    this.loadPermission = config?.loadPermission;
  }
}

export class ConfigFile implements IConfigFile {
  isEtiqaPlus: boolean;

  constructor(configFile: ConfigFile) {
    this.isEtiqaPlus = configFile?.isEtiqaPlus;
  }
}
