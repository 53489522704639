import { IMenu } from '../interfaces/menu.interface'

export class Menu implements IMenu {
  order: number;
  displayMenu: string;
  module: string;
  icon: string;
  aclCode?: string;
  enabled: boolean;
}
