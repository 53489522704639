import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Update } from '@ngrx/entity';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import * as xlsx from 'xlsx';

import { Case } from '../models/case.model';
import { CasesService } from '../services/cases.service';
import * as CasesActions from './cases.actions';
import * as CasesFeature from './cases.reducer';
import { of } from 'rxjs';

@Injectable()
export class CasesEffects {
  private _count = {
    table: 0,
    'new case': 0,
    'in progress': 0,
    history: 0,
  };

  getCount$ = createEffect(
    (actions$ = inject(Actions), casesService = inject(CasesService)) => {
      return actions$.pipe(
        ofType(CasesActions.getCasesCountInit),
        exhaustMap((action) =>
          casesService.getCasesCount(action.query).pipe(
            map((count) => {
              const key = action.key ? action.key.toLowerCase() : 'current';

              this._count = {
                ...this._count,
                [key]: count,
              };

              return CasesActions.getCasesCountSuccess({ count: this._count });
            }),
            catchError((error) => of(CasesActions.getCasesCountFailure({ error })))
          )
        ),
      )
    },
    { functional: true },
  );

  getAll$ = createEffect(
    (actions$ = inject(Actions), casesService = inject(CasesService)) => {
      return actions$.pipe(
        ofType(CasesActions.getCasesInit),
        exhaustMap((action) =>
          casesService.getCases(action.query).pipe(
            map((cases) => CasesActions.getCasesSuccess({ cases })),
            catchError((error) => of(CasesActions.getCasesFailure({ error })))
          )
        ),
      )
    },
    { functional: true },
  );

  getById$ = createEffect(
    (actions$ = inject(Actions), casesService = inject(CasesService)) => {
      return actions$.pipe(
        ofType(CasesActions.getCaseInit),
        exhaustMap((action) =>
          casesService.getCaseById(action.caseId).pipe(
            map((result) => {
              const caseData: Update<Case> = { id: result.id, changes: result };
  
              return CasesActions.getCaseSuccess({ caseData, selectedId: caseData.id });
            }),
            catchError((error) => of(CasesActions.getCaseFailure({ error })))
          )
        ),
      )
    },
    { functional: true },
  );

  register$ = createEffect(
    (actions$ = inject(Actions), casesService = inject(CasesService)) => {
      return actions$.pipe(
        ofType(CasesActions.registerCaseInit),
        exhaustMap((action) =>
          casesService.registerCase(action.payload).pipe(
            map((result) => {
              sessionStorage.setItem(result.ticketId, result.vehicleNo);
              return CasesActions.registerCaseSuccess({ caseData: result, createdId: result.id });
            }),
            catchError((error) => of(CasesActions.registerCaseFailure({ error })))
          )
        ),
      )
    },
    { functional: true },
  );

  cancel$ = createEffect(
    (actions$ = inject(Actions), casesService = inject(CasesService)) => {
      return actions$.pipe(
        ofType(CasesActions.cancelCaseInit),
        exhaustMap((action) =>
          casesService.cancelCase(action.payload, action.caseId).pipe(
            map((result) => {
              const caseData: Update<Case> = { id: result.id, changes: result };

              sessionStorage.removeItem(result.ticketId);
              localStorage.removeItem(result.ticketId);

              return CasesActions.cancelCaseSuccess({ caseData, cancelledId: caseData.id });
            }),
            catchError((error) => of(CasesActions.cancelCaseFailure({ error })))
          )
        ),
      )
    },
    { functional: true },
  );

  verifyTicket$ = createEffect(
    (actions$ = inject(Actions), casesService = inject(CasesService)) => {
      return actions$.pipe(
        ofType(CasesActions.verifyTicketInit),
        exhaustMap((action) =>
          casesService.verifyCaseTicket(action.ticketId, action.vehicleNo).pipe(
            map((result) => {
              const caseData: Case = result;
              const verifiedId = caseData.id;

              return CasesActions.verifyTicketSuccess({ caseData, verifiedId });
            }),
            catchError((error) => of(CasesActions.verifyTicketFailure({ error, verificationMeta: error.error.data })))
          )
        ),
      )
    },
    { functional: true },
  );

  export$ = createEffect(
    (actions$ = inject(Actions), casesService = inject(CasesService)) => {
      return actions$.pipe(
        ofType(CasesActions.exportCasesInit),
        exhaustMap((action) => {
          const query = {
            createdAt_gte: action.from,
            createdAt_lte: action.to,
            _limit: -1,
          };

          return casesService.getCases(query).pipe(
            map((cases) => {
              const newData = casesService.dataToExcel(cases);
              const worksheet = xlsx.utils.json_to_sheet(newData);
              const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
              const buffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
              const type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
              const blob: Blob = new Blob([buffer], {
                type: type,
              });

              return CasesActions.exportCasesSuccess({ blob });
            }),
            catchError((error) => of(CasesActions.exportCasesFailure({ error })))
          )
        }),
      )
    },
    { functional: true },
  );

  update$ = createEffect(
    (actions$ = inject(Actions), casesService = inject(CasesService)) => {
      return actions$.pipe(
        ofType(CasesActions.updateCaseInit),
        exhaustMap((action) =>
          casesService.updateCase(action.caseId, action.payload).pipe(
            map((result) => {
              const caseData: Update<Case> = { id: result.id, changes: result };

              return CasesActions.updateCaseSuccess({ caseData, updatedId: result.id });
            }),
            catchError((error) => of(CasesActions.exportCasesFailure({ error })))
          )
        ),
      )
    },
    { functional: true },
  );
}
