import { ICaseCarCondition } from '../interfaces/case-car-condition.interface';

export class CaseCarCondition implements ICaseCarCondition {
  id?: string;
  handbrake: boolean;
  gear: boolean;
  steering: boolean;
  lowered: boolean;
  parking: boolean;
  openSpace: boolean;

  constructor(caseCarCondition: CaseCarCondition) {
    if (caseCarCondition?.id) {
      this.id = caseCarCondition.id;
    }

    this.handbrake = caseCarCondition?.handbrake || false;
    this.gear = caseCarCondition?.gear || false;
    this.steering = caseCarCondition?.steering || false;
    this.lowered = caseCarCondition?.lowered || false;
    this.parking = caseCarCondition?.parking || false;
    this.openSpace = caseCarCondition?.openSpace || false;
  }
}
