import { Directive, OnDestroy } from '@angular/core';

import { Subject } from 'rxjs';

@Directive()
export abstract class BaseComponent implements OnDestroy {
  public destroy$: Subject<unknown> = new Subject();

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
