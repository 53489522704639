import { ICaseWorkshop } from '../interfaces/case-workshop.interface';

export class CaseWorkshop implements ICaseWorkshop {
  id?: string;
  name: string;
  phoneNo: string;
  faxNo: string;
  email: string;
  address1: string;
  address2: string;
  postcode: string;
  city: string;
  state: string;
  category: string;
  status: string;
  latitude?: number;
  longitude?: number;
  created_by?: string;
  updated_by?: string;

  constructor(caseWorkshop: CaseWorkshop) {
    if (caseWorkshop?.id) {
      this.id = caseWorkshop.id;
    }

    this.name = caseWorkshop?.name || null;
    this.phoneNo = caseWorkshop?.phoneNo || null;
    this.faxNo = caseWorkshop?.faxNo || null;
    this.email = caseWorkshop?.email || null;
    this.address1 = caseWorkshop?.address1 || null;
    this.address2 = caseWorkshop?.address2 || null;
    this.postcode = caseWorkshop?.postcode || null;
    this.city = caseWorkshop?.city || null;
    this.state = caseWorkshop?.state || null;
    this.category = caseWorkshop?.category || null;
    this.status = caseWorkshop?.status || null;
    this.latitude = caseWorkshop?.latitude || 0;
    this.longitude = caseWorkshop?.longitude || 0;
    this.created_by = caseWorkshop?.created_by || null;
    this.updated_by = caseWorkshop?.updated_by || null;
  }
}
