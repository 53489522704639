import { Component, ChangeDetectionStrategy, Input, Optional } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'ata-alert',
  templateUrl: './ata-alert.component.html',
  styleUrls: ['./ata-alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AtaAlertComponent {
  @Input() public message!: string;

  constructor(@Optional() public config?: DynamicDialogConfig) {
    if (this.config !== null) this.message = this.config?.data.message;
  }
}
