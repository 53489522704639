import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common'

// eslint-disable-next-line @nx/enforce-module-boundaries
import { LoginService } from '../../../../../../apps/dashboard/src/app/services/login.service';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { generateCryptoHash } from '@ata/utils';
import { AUTH_FEATURE_KEY } from '@ata/shared/data-access/auth';
import Swal from 'sweetalert2';

@Injectable()
export class AtaHttpInterceptor implements HttpInterceptor {
  constructor(public router: Router, private loginService: LoginService, private location: Location) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let cloneRequest = null;
    const header: { [key: string]: string } = {};
    const body = {...request.body};
    const jwt = localStorage.getItem(`${AUTH_FEATURE_KEY}.token`);

    // @TODO: remove this once all app is able to use cookie
    if (request.method == 'GET' && jwt) {
      header.authorization = `Bearer ${jwt}`;
    }

    if (request.method == 'POST' || request.method == 'PUT') {
      body['hash'] = generateCryptoHash(JSON.stringify(request.body).replace(/{|:|\s|}|"|'|-/g, ''));
    }

    if (request.body instanceof FormData) {
      cloneRequest = request.clone({ setHeaders: header, withCredentials: true});
    } else {
      cloneRequest = request.clone({ setHeaders: header, body, withCredentials: true});
    }

    return next.handle(cloneRequest).pipe(
      catchError((error: HttpErrorResponse) => {
        if (401 === error.status) {
          // TODO: refactor this because it cause circular-dependencies with dashboard app
          this.loginService.logout();

          return throwError(() => 'Your session has expired, try login again');
        }

        if (403 === error.status) {          
          Swal.fire({
            icon: 'error',
            title: 'Error Message',
            text: "You're not authorized to access this function",
            showConfirmButton: true,
          }).then((result: any) => {
            if (result.isConfirmed) {              
              this.location.back();
            }
          });
        }

        return throwError(() => error);
      })
    );
  }
}
