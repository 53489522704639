<div class="modal-background" [ngClass]="{'show': showPopup}"></div>

<div class="overlay-container popup-container" *ngIf="showPopup">
  <div class="content-container">
    <h6 class="ata-mb-6 ata-font-bold ata-text-xl ata-text-center secondary">{{ title }}</h6>
    <main class="ata-font-bold ata-text-center" style="color: black">      
      <div class="ata-mb-4">{{ descriptionText }}</div>     
      <div>{{ confirmationText }}</div>               
    </main>
    <div class="ata-flex ata-flex-col">
      <button style="margin-top: 10px" class="ata-btn ata-btn-primary ata-btn-block" (click)="onConfirmClicked()">{{ confirmButtonText }}</button> 
      <button class="underline-text" (click)="onCancel()">{{ cancelButtonText }}</button>
    </div>    
  </div>
</div>