<label
    class="ata-block"
    [ngClass]="{
        'ata-cursor-pointer': !disabled,
        'ata-cursor-not-allowed ata-opacity-50': disabled
    }"
>
    <input class="ata-hidden" type="radio" (ngModelChange)="onInputChange($event)" [name]="name" [value]="value" [(ngModel)]="model" [disabled]="disabled" />
    <span class="ata-flex ata-flex-col ata-items-center ata-justify-center">
        <svg-icon
            [src]="checked ? checkedIcon : uncheckedIcon"
            [style.color]="checked ? checkedColor : uncheckedColor"
            [svgStyle]="{ 'width.em': 2.5, 'height.em': 2.5 }"
        ></svg-icon>
        <span class="ata-grow ata-font-semibold ata-text-xs ata-uppercase ata-text-slate-500" *ngIf="label">{{ label }}</span>
    </span>
</label>
