import { IPermissionsType } from '../interfaces/permissions-type.interface';

export class PermissionsType implements IPermissionsType {
  type: string;
  controller: string;
  action: string;
  enabled: boolean;

  constructor(permissionsType: IPermissionsType) {
    this.type = permissionsType?.type;
    this.controller = permissionsType?.controller;
    this.action = permissionsType?.action;
    this.enabled = permissionsType?.enabled;
  }
}
