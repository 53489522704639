import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'ata-input-checkbox',
  templateUrl: './input-checkbox.component.html',
  styleUrls: ['./input-checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputCheckboxComponent),
      multi: true
    }
  ]
})
export class InputCheckboxComponent implements ControlValueAccessor {
  public checked!: boolean;

  public model!: any;

  @Input() label = "";

  @Input() value = "";

  @Input() disabled = false;

  @Input() readonly = false;

  @Input() hideIcon = false;

  @Output() valueChange = new EventEmitter();

  onChange: any = () => {
    // empty for now
  }

  onTouch: any = () => {
    // empty for now
  }  

  onInputChange(checked: boolean, val: any) { 
    if (this.disabled || this.readonly) return;
    
    this.checked = checked && this.value === val;
    this.onChange(checked && this.value ? val : false);
    this.valueChange.emit(checked && this.value ? val : null);
  }

  writeValue(value: any): void {  
    this.model = value;
    this.checked = value == this.value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
