export interface ICaseWorkshop {
  id?: string;
  name: string;
  phoneNo: string;
  faxNo: string;
  email: string;
  address1: string;
  address2: string;
  postcode: string;
  city: string;
  state: string;
  category: string;
  status: string;
  latitude?: number;
  longitude?: number;
  created_by?: string;
  updated_by?: string;
}
