import { NgModule } from '@angular/core';

import { CoreModule } from '@ata/shared/core';
import { UiCommonModule } from '@ata/shared/ui/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutModule } from './layout/layout.module';
import { NotFoundComponent } from './features/not-found/not-found.component';
import { environment } from '../environments/environment';

@NgModule({
  imports: [
    CoreModule.forRoot(environment as any),
    UiCommonModule,
    LayoutModule,
    AppRoutingModule,
  ],
  declarations: [AppComponent, NotFoundComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}
