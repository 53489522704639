import { IPermissionsRole, IRole } from '../interfaces/permissions-role.interface';
import { Identity } from './identity.model';
import { PermissionsType } from './permissions-type.model';

export class Role implements IRole {
  roles: Array<PermissionsRole>;

  constructor(role: IRole) {
    this.roles = role?.roles;
  }
}

export class PermissionsRole implements IPermissionsRole {
  id: string;
  name: string;
  description: string;
  permissions: Array<PermissionsType>;
  identity: Identity;

  constructor(permissionsRole: IPermissionsRole) {
    this.id = permissionsRole?.id;
    this.name = permissionsRole?.name;
    this.description = permissionsRole?.description;
    this.permissions = permissionsRole?.permissions;
    this.identity = new Identity(permissionsRole?.identity);
  }
}
