import { Action } from '@ngrx/store';
import { EntityAction, DefaultPersistenceResultHandler } from '@ngrx/data';

export class CustomPersistenceResultHandler extends DefaultPersistenceResultHandler {
  handleSuccess(originalAction: EntityAction): (data: any) => Action {
    const actionHandler = super.handleSuccess(originalAction);

    return (data: any) => {
      const action = actionHandler.call(this, data);

      if (action && data) {
        if (data.count) {
          (action as any).payload.count = data.count;
        }

        if (data.data) {
          (action as any).payload.data = data.data;
        }
      }

      return action;
    };
  }
}
