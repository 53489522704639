import { IIdentity } from '../interfaces/identity.interface';

export class Identity implements IIdentity {
  assignmentAccept: boolean;
  assignmentPriceView: boolean;
  assignmentReassign: boolean;
  caseClose: boolean;
  caseCancel: boolean;
  csvExport: boolean;

  constructor(identity: Identity) {
    this.assignmentAccept = identity?.assignmentAccept;
    this.assignmentPriceView = identity?.assignmentPriceView;
    this.assignmentReassign = identity?.assignmentReassign;
    this.caseClose = identity?.caseClose;
    this.caseCancel = identity?.caseCancel;
    this.csvExport = identity?.csvExport;
  }
}
