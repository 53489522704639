import { createReducer, on, Action } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import * as CasesActions from './cases.actions';
import { Case } from '../models/case.model';

export const CASES_FEATURE_KEY = 'cases';

export interface CasesState extends EntityState<Case> {
  selectedId?: string;
  createdId?: string;
  duplicatedId?: string;
  cancelledId?: string;
  verifiedId?: string;
  updatedId?: string;
  loading: boolean;
  verifying: boolean;
  updating: boolean;
  exporting: boolean;
  count: {
    current: number;
    'new case': number;
    'in progress': number;
    history: number;
  };
  error?: string | null;
  blob?: Blob;
  verificationMeta?: { counter: number; limit: number; ttl: number };
}

export interface CasesPartialState {
  readonly [CASES_FEATURE_KEY]: CasesState;
}

export const casesAdapter: EntityAdapter<Case> = createEntityAdapter<Case>();

export const initialState: CasesState = casesAdapter.getInitialState({
  loading: false,
  updating: false,
  verifying: false,
  exporting: false,
  count: {
    current: 0,
    'new case': 0,
    'in progress': 0,
    history: 0,
  }
});

const casesReducer = createReducer(
  initialState,
  on(CasesActions.getCasesCountInit, (state, { query }) => ({ ...state, error: null, query })),
  on(CasesActions.getCasesCountSuccess, (state, { count }) => ({ ...state, error: null, count })),
  on(CasesActions.getCasesCountFailure, (state, { error }) => ({ ...state, error })),

  on(CasesActions.getCasesInit, (state, { query }) => ({ ...state, loading: true, error: null, query })),
  on(CasesActions.getCasesSuccess, (state, { cases }) => casesAdapter.setAll(cases, { ...state, loading: false })),
  on(CasesActions.getCasesFailure, (state, { error }) => ({ ...state, loading: false, error })),

  on(CasesActions.getCaseInit, (state) => ({ ...state, loading: true, error: null })),
  on(CasesActions.getCaseSuccess, (state, { caseData, selectedId }) => casesAdapter.updateOne(caseData, { ...state, selectedId })),
  on(CasesActions.getCaseFailure, (state, { error }) => ({ ...state, error, loading: false })),

  on(CasesActions.updateCaseInit, (state) => ({ ...state, updating: true, error: null })),
  on(CasesActions.updateCaseSuccess, (state, { caseData, updatedId }) => casesAdapter.updateOne(caseData, { ...state, updating: false, updatedId })),
  on(CasesActions.updateCaseFailure, (state, { error }) => ({ ...state, error, updating: false })),

  on(CasesActions.verifyTicketInit, (state) => {
    return { ...state, verifying: true, error: null };
  }),
  on(CasesActions.verifyTicketSuccess, (state, { caseData, verifiedId }) => {
    return casesAdapter.upsertOne(caseData, { ...state, verifying: false, verifiedId });
  }),
  on(CasesActions.verifyTicketFailure, (state, { error, verificationMeta }) => {
    return { ...state, error, verificationMeta, verifying: false };
  }),

  on(CasesActions.registerCaseInit, (state) => ({ ...state, loading: true, error: null })),
  on(CasesActions.registerCaseSuccess, (state, { caseData, createdId }) => casesAdapter.addOne(caseData, { ...state, loading: false, createdId })),
  on(CasesActions.registerCaseDuplicate, (state, { caseData, duplicatedId }) => casesAdapter.addOne(caseData, { ...state, loading: false, duplicatedId })),
  on(CasesActions.registerCaseFailure, (state, { error }) => ({ ...state, error, loading: false })),

  on(CasesActions.cancelCaseInit, (state) => ({ ...state, loading: true, error: null })),
  on(CasesActions.cancelCaseSuccess, (state, { caseData, cancelledId }) => casesAdapter.updateOne(caseData, { ...state, loading: false, cancelledId })),
  on(CasesActions.cancelCaseFailure, (state, { error }) => ({ ...state, error, loading: false })),

  on(CasesActions.exportCasesInit, (state) => ({ ...state, exporting: true, error: null })),
  on(CasesActions.exportCasesSuccess, (state, { blob }) => ({ ...state, exporting: false, blob })),
  on(CasesActions.exportCasesFailure, (state, { error }) => ({ ...state, error }))
);

export function reducer(state: CasesState | undefined, action: Action) {
  return casesReducer(state, action);
}
