<span
    class="ata-flex ata-items-center ata-bg-white ata-rounded-base ata-border ata-border-slate-300 hover:ata-bg-slate-50"
    [ngClass]="{
        'ata-px-4 ata-py-3': !compact,
        'ata-text-xs ata-px-3 ata-py-2': compact
    }"
>
    <svg-icon
        class="ata-flex ata-text-xs ata-text-slate-500"
        [ngClass]="{
            'ata-order-1': iconPosition === 'right',
            'ata-pr-2': iconPosition === 'left'
        }"
        [src]="iconPath"
        [svgStyle]="{ 'width.em': compact ? 1 : 2.5, 'height.em': compact ? 1 : 2.5 }"
    ></svg-icon>
    <span class="ata-grow">{{ label }}</span>
</span>
