import { HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Addon } from '@ata/shared/data-access/addons';
import { ConfigService } from '@ata/shared/data-access/configs';
import { BaseApi, formatDate } from '@ata/utils';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { firstValueFrom, lastValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as xlsx from 'xlsx';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';

import { CASE_ENTITY_NAME } from '../case.constant';
import { Case } from '../models/case.model';
import { stringify } from 'qs';
import { ECaseSubStatus } from '../enums/case-status.enum';
import {ICaseData} from "../../../../../../../apps/dashboard/src/app/services/case.service";

@Injectable({ providedIn: 'root' })
export class CaseService extends EntityCollectionServiceBase<Case> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory, private configService: ConfigService, private api: BaseApi) {
    super(CASE_ENTITY_NAME, serviceElementsFactory);
  }

  public exportCasesToExcel = (queryParams) => {
    return this.api.get(`${this.configService.config.providerEndpoint}/assignments/${queryParams.createdAt_gte}/${queryParams.createdAt_lte}`).pipe(
      map((response: HttpResponse<any>) => {
        const assignments = response.body;

        const worksheet = xlsx.utils.json_to_sheet(
          assignments.map((assignment) => ({
            'Created At': formatDate(assignment?.createdAt),
            Channel: assignment?.channel,
            'Ticket ID': assignment?.ticketId,
            'Assignment ID': assignment?.assignmentId,
            'Assignment Type': assignment?.personInCharge === 'Automation' ? 'Automation' : 'Manual',
            'Customer Name': assignment?.name,
            'Customer Phone Number 1': assignment?.phoneNo,
            'Customer Phone Number 2': assignment?.altPhoneNo ? assignment?.altPhoneNo[0] : null,
            'Customer Phone Number 3': assignment?.altPhoneNo ? assignment?.altPhoneNo[1] : null,
            Entity: assignment?.entity,
            'Policy Benefit': assignment?.policyCoverage,
            'Vehicle Number': assignment?.vehicleNo,
            // 'Vehicle Make': assignment?.vehicleBrand?.split('-')[1],
            'Vehicle Make': assignment?.vehicleDetail?.make?.name,
            'Vehicle Model': assignment?.vehicleDetail?.description,
            'Vehicle Manufacturing Year': assignment?.vehicleYear > 0 ? assignment?.vehicleYear : '',
            'Service Required': assignment?.services ? assignment?.services.map((service) => service.service).toString() : '',
            'Case status': assignment?.subStatus ? assignment?.subStatus : assignment?.status,
            Mileage: assignment?.distance || 0,
            'Add on services': assignment?.addOnService.toString(),
            'Provider Name': assignment?.provider?.name,
            'Provider Type': assignment?.provider?.providerType,
            'Driver Distance (KM)': assignment?.driverDistance,
            'Customer Location': assignment?.location,
            'Latitude, Longitude of Customer Location': assignment?.geoLocation,
            'Police Station Address': assignment?.policeStation?.address,
            'DSP Company Name': assignment?.tracking?.companySpName,
            'Workshop Name': assignment?.workshop?.name,
            'Workshop Address': assignment?.workshop?.address1,
            'Latitude, Longitude of Workshop': this._getWorkshopLatLong(assignment?.workshop?.latitude, assignment?.workshop?.longitude),
            'Assigned Rider Name': assignment?.assignee?.displayName,
            'Location of Driver upon Assignment': this._statusLocation(assignment?.activityLog, 'accepted'),

            State: null,
            Region: assignment?.billing?.region,
            'Charge to Etiqa': this._calculateChargeToEtiqa(assignment?.billing?.amount, assignment?.billing?.payByCustomer),
            'Collect From Customer': assignment?.billing?.payByCustomer || 0,
            Total: assignment?.billing?.amount || 0,
            'Time Case Open': this._caseTimeline(assignment?.activityLog, 'open'),
            'Time Case Assigned': this._caseTimeline(assignment?.activityLog, 'assigned'),
            'Time Case Accepted': this._caseTimeline(assignment?.activityLog, 'accepted'),
            'Time Case Arrived at Customer': this._caseTimeline(assignment?.activityLog, 'arrived_at_customer'),
            'Time Case Arrived at Workshop': this._caseTimeline(assignment?.activityLog, 'arrived_at_workshop'),
            'Time Case Cancelled': this._caseTimeline(assignment?.activityLog, 'cancelled'),
            'Time Case Resolved': this._caseTimeline(assignment?.activityLog, 'resolved'),
            'Time Case Resolved - Pending Action': this._caseTimeline(assignment?.activityLog, ECaseSubStatus.PENDING_ACTION),
            'Time Case Resolved - Pending Review': this._caseTimeline(assignment?.activityLog, ECaseSubStatus.PENDING_REVIEW),
            'Time Case Closed': this._caseTimeline(assignment?.activityLog, 'closed'),
            'Interval Time for Turnaround Time (min)': this._calculateTimeDifference(
              this._caseActionTime(assignment?.activityLog, 'assigned'),
              this._caseActionTime(assignment?.activityLog, 'arrived_at_customer')
            ),
            'Interval Time for Case Completion Time (min)': this._calculateTimeDifference(
              this._caseActionTime(assignment?.activityLog, 'assigned'),
              this._caseActionTime(assignment?.activityLog, 'resolved')
            ),
            'Person In Charge': assignment?.personInCharge,
            'Second Towing': assignment?.isSecondTowing === true ? 'Yes' : 'No',
            Fraud: assignment?.isPotentialFraud === true ? 'Yes' : 'No',
            'Multiple RSA': assignment?.isMultipleRsa === true ? 'Yes' : 'No',
            'Suggested Workshop': assignment?.workshop?.isSuggestedWorkshop === true ? 'Yes' : '',
          }))
        );
        const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
        const buffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob: Blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });

        return blob;
      })
    );
  };

  private _caseTimeline = (activityLogs: any[], status: string) => {
    if (activityLogs && activityLogs.length > 0) {
      const s = activityLogs.find((activityLog) => activityLog?.status === status);

      if (s) {
        return formatDate(s?.createdDate);
      }
    }

    return null;
  };

  private _calculateChargeToEtiqa = (amount: number, chargeToCustomer: number) => {
    if (amount !== undefined && chargeToCustomer !== undefined) {
      return amount - chargeToCustomer;
    }

    return 0;
  };

  private _caseActionTime = (activityLogs: any[], status: string) => {
    if (activityLogs && activityLogs.length > 0) {
      const s = activityLogs.find((activityLog) => activityLog?.status === status);

      if (s) {
        return s?.createdDate;
      }
    }
    return null;
  };

  private _calculateTimeDifference = (startDate, endDate) => {
    if (startDate && endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      const msInMinute = 60 * 1000;

      const res = Math.round(Math.abs(end.getTime() - start.getTime()) / msInMinute);
      return res;
    }

    return null;
  };

  private _statusLocation = (activityLogs: any[], status: string) => {
    if (activityLogs && activityLogs.length > 0) {
      const s = activityLogs.find((activityLog) => activityLog?.status === status);

      if (s) {
        return s?.location;
      }
    }

    return null;
  };

  private _getWorkshopLatLong(lat, long) {
    if (lat || long) {
      return lat.toString() + ', ' + long.toString();
    }

    return null;
  }

  setAttachmentData(caseId: string, attachmentData: any, action: number): Observable<any> {
    return this.api.put(`${this.configService.config.systemEndpoint}/cases/${caseId}/upload-attachment/${action}`, attachmentData);
  }

  getSupportingDocument = (filePath: string) => {
    let params = new HttpParams();

    if (filePath) {
      params = params.append('file_path', filePath);
    }

    return this.api.get(`${this.configService.config.systemEndpoint}/cases/supporting-document`, params);
  };

  removeSupportingDocument = (caseId: string, payload: any) => {
    return this.api.put(`${this.configService.config.systemEndpoint}/cases/${caseId}/upload-attachment/2`, payload);
  };

  renameSupportingDocument = (caseId: string, payload: any) => {
    return this.api.put(`${this.configService.config.systemEndpoint}/cases/${caseId}/upload-attachment/3`, payload);
  };

  async getTempUrl(filePath: any) {
    return firstValueFrom(this.api.post(`${this.configService.config.systemEndpoint}/cases/tempUrl`, filePath));
  }

  public reuploadAttachmentRequest(caseId: string, payload: any): Observable<any> {
    return this.api.put(`${this.configService.config.systemEndpoint}/cases/reupload-attachment-request/${caseId}`, payload).pipe(
      map((res) => res.body),
      catchError((error) => of(error.error?.message))
    );
  }
  public getCasesV2 = (
    query: any,
    options: any
  ): Observable<{
    data: Case[];
    code: number;
    meta: any;
    success: boolean;
  }> => {
    const urlParams = stringify({ query, options }, { encode: false });

    return this.api.get(`${this.configService.config.systemEndpointV2}/cases?${urlParams}`).pipe(
      map((response) => {
        return {
          data: response.body.data.map((c) => new Case(c)),
          code: response.status,
          meta: response.body.meta,
          success: response.ok,
        };
      })
    );
  };
  public getCasesCountV2 = (
    query: any
  ): Observable<{
    data: number;
    code: number;
    success: boolean;
  }> => {
    const urlParams = stringify({ query }, { encode: false });

    return this.api.get(`${this.configService.config.systemEndpointV2}/cases/count?${urlParams}`).pipe(
      map((response) => {
        return {
          data: response.body.data,
          code: response.status,
          success: response.ok,
        };
      })
    );
  };

  updateCase(data: any, caseId): Observable<any> {
    return this.api.put(`${this.configService.config.systemEndpoint}/cases/${caseId}`, data);
  }

  getPresignedUrl(data: any): Observable<any> {
    const encoded = encodeURIComponent(data);
    return this.api.get(`${this.configService.config.systemEndpoint}/fileupload/presigned?key=` + encoded);
  }
  
}

export enum CaseStatus {
  DRAFT = 'draft', // not saved..
  NEW_REQUEST = 'new_request',
  OPEN = 'open',
  ASSIGNED = 'assigned',
  ACCEPTED = 'accepted',
  ARRIVED_AT_CUSTOMER = 'arrived_at_customer',
  ARRIVED_AT_WORKSHOP = 'arrived_at_workshop',
  ARRIVED_AT_POLICE = 'arrived_at_police_station',
  TOWING = 'towing_to_workshop',
  PENDING = 'pending',
  CLOSED = 'closed',
  RESOLVED = 'resolved',
  CANCELLED = 'cancelled',
  UNKNOWN = 'unknown', // NOT in use, but when failed to get status
  ERROR = 'error', //something bad happened beyond recovery need manual intervention
}
