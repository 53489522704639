import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { BaseComponent } from '@ata/utils';

@Component({
  selector: 'ata-atau-default',
  templateUrl: './layout-default.component.html',
  styleUrls: ['./layout-default.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutDefaultComponent extends BaseComponent {
  public selectedLanguage = this.translateService.getBrowserLang();

  public currentLanguage = '';

  public sidebarToggle = false;

  public languageOptions = [
    {
      label: 'English',
      value: 'en',
    },
    {
      label: 'Bahasa Melayu',
      value: 'ms',
    },
  ];
  public showMessage = false;

  public showHotlineDialog = false;

  public isFixed = false;
  public etiqaPlusFlag;

  constructor(private translateService: TranslateService, private router: Router) {
    super();
    this.etiqaPlusFlag = localStorage.getItem('isEtiqaPlus');

    this.setLanguage(this.selectedLanguage);
    this.showMessageBox();
  }

  public openCallPopup = (phoneNumber: string) => {
    window.location.href = `tel:${phoneNumber}`;
  };

  public setLanguage = (lang: string) => {
    this.translateService.use(lang);
    if (this.selectedLanguage === 'en') {
      this.currentLanguage = 'English';
    } else {
      this.currentLanguage = 'Bahasa Melayu';
    }
  };

  public reloadPage = () => {
    if (this.router.url === '/help') {
      window.location.reload();
    } else {
      this.router.navigate(['/help']);
    }
  };

  public closeMessage = () => {
    this.showMessage = false;
  };

  public showMessageBox = () => {
    if (this.router.url.includes('/help')) {
      this.showMessage = true;
    }
  };
}
