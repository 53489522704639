import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService, Config } from '@ata/shared/data-access/configs';
import { Menu } from '@ata/shared/core';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  public config: Config;

  constructor(private configService: ConfigService, private http: HttpClient) {
    this.config = configService.config;
  }

  getMenuList() {
    return this.http.get<Menu[]>("assets/menu.json");
  }
}
