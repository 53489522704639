import { IIdentity } from './identity.interface';
import { IPermissionsType } from './permissions-type.interface';

export interface IRole {
  roles: Array<IPermissionsRole>;
}

export interface IPermissionsRole {
  id: string;
  name: string;
  description: string;
  permissions: Array<IPermissionsType>;
  identity: IIdentity;
}
