import { Injectable, inject } from '@angular/core';

import { createEffect, Actions, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { of } from 'rxjs';

import * as AuthActions from './auth.actions';
import { AUTH_FEATURE_KEY } from './auth.constants';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthEffects {
  requestOtp$ = createEffect(
    (actions$ = inject(Actions), authService = inject(AuthService)) => {
      return actions$.pipe(
        ofType(AuthActions.getAuthOTPInit),
        exhaustMap((action) =>
          authService.requestOTP(action.payload).pipe(
            map(({ meta }) => {
              const otp = {
                ttl: meta.ttl,
                otp: meta.otp,
                requestedAt: new Date().toISOString(),
                phoneNumber: action.payload.phoneNumber,
              };
              
              localStorage.setItem(`${AUTH_FEATURE_KEY}.otp`, JSON.stringify(otp));

              return AuthActions.getAuthOTPSuccess({ otp });
            }),
            catchError((error) => of(AuthActions.getAuthOTPFailure({ error }))),
          ),
        ),
      )
    },
    { functional: true },
  );

  verifyOtp$ = createEffect(
    (actions$ = inject(Actions), authService = inject(AuthService)) => {
      return actions$.pipe(
        ofType(AuthActions.verifyAuthOTPInit),
        exhaustMap((action) =>
          authService.verifyOTP(action.payload).pipe(
            map(() => AuthActions.verifyAuthOTPSuccess()),
            catchError((error) => of(AuthActions.verifyAuthOTPFailure({ error }))),
          ),
        ),
      )
    },
    { functional: true },
  );

  login$ = createEffect(
    (actions$ = inject(Actions), authService = inject(AuthService)) => {
      return actions$.pipe(
        ofType(AuthActions.requestAuthLoginInit),
        exhaustMap((action) =>
          authService.login(action.payload).pipe(
            map(({ jwt, user }) => {
              localStorage.removeItem(`${AUTH_FEATURE_KEY}.otp`);
              localStorage.setItem(`${AUTH_FEATURE_KEY}.user`, JSON.stringify(user));
              localStorage.setItem(`${AUTH_FEATURE_KEY}.token`, jwt);

              return AuthActions.requestAuthLoginSuccess({ jwt });
            }),
            catchError((error) => of(AuthActions.requestAuthLoginFailure({ error }))),
          ),
        ),
      )
    },
    { functional: true },
  );

  logout$ = createEffect(
    (actions$ = inject(Actions), authService = inject(AuthService)) => {
      return actions$.pipe(
        ofType(AuthActions.requestAuthLogoutInit),
        exhaustMap((action) =>
          authService.logout().pipe(
            map(() => {
              // Clear all auth data
              Object.keys(localStorage)
                .filter(x => x.startsWith(`${AUTH_FEATURE_KEY}`))
                .forEach(x => localStorage.removeItem(x));

              return AuthActions.requestAuthLogoutSuccess();
            }),
            catchError((error) => of(AuthActions.requestAuthLogoutFailure({ error }))),
          ),
        ),
      )
    },
    { functional: true },
  );
}
