import { genSaltSync, hashSync } from 'bcryptjs';
import { SHA256, enc } from 'crypto-js';
import { ataSystem } from '@ata/shared'

export const generateHash = (payload: string) => {
  const salt = genSaltSync(10);
  const hash = hashSync(`${ataSystem.secretKey}${payload}`, salt);

  return hash;
};

export const generateCryptoHash = (payload: string) => {
  const hash = SHA256(`${ataSystem.secretKey}${payload}`).toString(enc.Hex);
  
  return hash;
};