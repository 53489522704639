import { ICaseAttachment } from '../interfaces/case-attachment.interface';

export class CaseAttachment implements ICaseAttachment {
  id?: string;
  source: string;
  title: string;

  constructor(caseAttachment: CaseAttachment) {
    if (caseAttachment?.id) {
      this.id = caseAttachment.id;
    }

    this.source = caseAttachment?.source || null;
    this.title = caseAttachment?.title || null;
  }
}
