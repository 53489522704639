import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';

import { ICase } from '../interfaces/case.interface';
import { ICasesQuery } from '../dtos/cases-query.dto';
import { Case } from '../models/case.model';
import { ECaseVehicleType } from '../enums/case-vehicle-type.enum';

/**
 * Register Case
 * @param caseData
 */
export class RegisterCase {
  static readonly type = '[Cases] Register Case';

  constructor(public caseData: Case) {}
}


/**
 * Set Vehicle Type
 * @param vehicleType
 */
export class SetVehicleType {
  static readonly type = '[Cases] Set Vehicle Type';

  constructor(public vehicleType: ECaseVehicleType) {}
}


/**
 * Get Case
 * @param caseId
 */
export class GetCase {
  static readonly type = '[Cases] Get Case';

  constructor(public params: { caseId: string }) {}
}


/**
 * Verify Case
 * @param caseId
 */
export class VerifyCase {
  static readonly type = '[Cases] Verify Case';

  constructor(public params: { ticketId: string, vehicleNo: string }) {}
}


/**
 * Cancel Case
 * @param caseId
 */
export class CancelCase {
  static readonly type = '[Cases] Cancel Case';

  constructor(public params: { caseId: string, payload: any }) {}
}


export const getCasesInit = createAction('[Cases/API] Get Cases Init', props<{ query: ICasesQuery }>());
export const getCasesSuccess = createAction('[Cases/API] Get Cases Success', props<{ cases: ICase[] }>());
export const getCasesFailure = createAction('[Cases/API] Get Cases Failure', props<{ error: any }>());

export const getCaseInit = createAction('[Cases/API] Get Case Init', props<{ caseId: string }>());
export const getCaseSuccess = createAction('[Cases/API] Get Case Success', props<{ caseData: Update<Case>; selectedId: string }>());
export const getCaseFailure = createAction('[Cases/API] Get Case Failure', props<{ error: any }>());

export const getCasesCountInit = createAction('[Cases/API] Get Cases Count Init', props<{ query?: ICasesQuery; key?: string }>());
export const getCasesCountSuccess = createAction('[Cases/API] Get Cases Count Success', props<{ count: any }>());
export const getCasesCountFailure = createAction('[Cases/API] Get Cases Count Failure', props<{ error: any }>());

export const verifyTicketInit = createAction('[Cases/API] Verify Case Ticket Init', props<{ ticketId: string; vehicleNo: string }>());
export const verifyTicketSuccess = createAction('[Cases/API] Verify Case Ticket Success', props<{ caseData: Case; verifiedId: string }>());
export const verifyTicketFailure = createAction('[Cases/API] Verify Case Ticket Failure', props<{ error: any; verificationMeta: any }>());

export const registerCaseInit = createAction('[Cases/API] Register Case Init', props<{ payload: Case }>());
export const registerCaseSuccess = createAction('[Cases/API] Register Case Success', props<{ caseData: Case; createdId: string }>());
export const registerCaseDuplicate = createAction('[Cases/API] Register Case Duplicate', props<{ caseData: Case; duplicatedId: string }>());
export const registerCaseFailure = createAction('[Cases/API] Register Case Failure', props<{ error: any }>());

export const cancelCaseInit = createAction('[Cases/API] Cancel Case Init', props<{ payload: any; caseId: string }>());
export const cancelCaseSuccess = createAction('[Cases/API] Cancel Case Success', props<{ caseData: Update<Case>; cancelledId: string }>());
export const cancelCaseFailure = createAction('[Cases/API] Cancel Case Failure', props<{ error: any }>());

export const exportCasesInit = createAction('[Cases/API] Export Cases Init', props<{ from: string; to: string }>());
export const exportCasesSuccess = createAction('[Cases/API] Export Cases Success', props<{ blob: Blob }>());
export const exportCasesFailure = createAction('[Cases/API] Export Cases Failure', props<{ error: any }>());

export const updateCaseInit = createAction('[Cases/API] Update Case Init', props<{ caseId: string; payload: any }>());
export const updateCaseSuccess = createAction('[Cases/API] Update Case Success', props<{ caseData: Update<Case>; updatedId: string }>());
export const updateCaseFailure = createAction('[Cases/API] Update Case Failure', props<{ error: any }>());
