import { IWorkshop, Workshop } from './case-workshop.data';

/**
 * @deprecated
 */
export interface ICaseTowing {
  id?: string;
  workshop?: IWorkshop;
  preferredWorkshopName?: string;
  usePreferredWorkshop?: boolean;
  preferredWorkshopFullAddress?: string;
  preferredWorkshopLatitude?: number;
  preferredWorkshopLongitude?: number;
}

/**
 * @deprecated
 */
export class CaseTowing implements ICaseTowing {
  id?: string;
  _id?: string; //optional field from database
  workshop: Workshop;
  preferredWorkshopName: string;
  usePreferredWorkshop: boolean;
  preferredWorkshopFullAddress: string;
  preferredWorkshopLatitude: number;
  preferredWorkshopLongitude: number;
  workshopRemark?: string;
}
