import { ICaseActivityLog } from './case-activity-log.interface';
import { ICaseAdditionalAddons } from './case-additional-addons.interface';

export interface ICaseBilling {
  id: string;
  invoiceNo: string;
  assignmentId: string;
  case: string;
  amount: number;
  payByCustomer: number;
  product: string;
  status: string;
  serviceProvider: string;
  activityLog: ICaseActivityLog;
  region: string;
  additionalData: ICaseAdditionalAddons;
  created_by: string;
  updated_by: string;
}
