import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ConfigFile, ConfigService } from '@ata/shared/data-access/configs';

import { initializeApp } from 'firebase/app';
import { initializeAnalytics } from 'firebase/analytics';
import { map } from 'rxjs';

@Component({
  selector: 'ata-atau',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  public isCollapsed = false;

  public isLogin = false;

  public notificationCount = 1;

  public showSplash = true;

  private _firebase;
  public _etiqaPlus;

  constructor(private configService: ConfigService, private http: HttpClient) {}

  async ngOnInit(): Promise<void> {
    this.setupFirebase();
    this._etiqaPlus = await this.http.get<ConfigFile>("assets/config-file.json").pipe(map((configFile) => configFile.isEtiqaPlus)).toPromise();
    localStorage.setItem('isEtiqaPlus', this._etiqaPlus);
  }

  private setupFirebase = () => {
    this._firebase = initializeApp(this.configService.config.firebase);
    initializeAnalytics(this._firebase);
  };
}
