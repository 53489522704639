<div class="ata-card" [class.ata-card--elevation]="elevation">
    <div class="ata-card__header">
        <ng-content select="[header]"></ng-content>
    </div>
    <div class="ata-card__content">
        <ng-content></ng-content>
    </div>
    <div class="ata-card__footer">
        <ng-content select="[footer]"></ng-content>
    </div>
</div>