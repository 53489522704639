import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UiCommonModule } from '@ata/shared/ui/common';

import { LayoutEmptyComponent } from './components/layout-empty/layout-empty.component';
import { LayoutDefaultComponent } from './components/layout-default/layout-default.component';

@NgModule({
  imports: [CommonModule, UiCommonModule],
  declarations: [LayoutEmptyComponent, LayoutDefaultComponent],
  exports: [LayoutEmptyComponent, LayoutDefaultComponent],
})
export class LayoutModule {}
