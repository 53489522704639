<label
    class="ata-block"
    [ngClass]="{
        'ata-cursor-pointer': !disabled,
        'ata-cursor-not-allowed ata-opacity-50': disabled
    }"
>
    <input class="ata-hidden" type="radio" (ngModelChange)="onInputChange($event)" [name]="name" [value]="value" [(ngModel)]="model" [disabled]="disabled" />
    <span
        class="ata-flex ata-items-center ata-rounded-base ata-border ata-px-4 ata-py-3"
        [ngClass]="{
            'ata-border-primary-500 ata-bg-primary-50 hover:ata-bg-primary-50': checked,
            'ata-border-slate-300 hover:ata-bg-slate-50': !checked
        }"
    >
        <img *ngIf="preIcon" [src]="preIcon" />         
        <svg-icon *ngIf="!hideIcon"
            class="ata-flex ata-order-1 ata-text-xs"
            [src]="checked ? 'assets/svgs/checkbox-circle-checked.svg' : 'assets/svgs/checkbox-circle-blank.svg'"
            [svgStyle]="{ 'width.em': 2.5, 'height.em': 2.5 }"
            [ngClass]="{
                'ata-text-primary-500': checked,
                'ata-text-slate-400': !checked
            }"
        ></svg-icon>               
        <span class="ata-grow">{{ label }}</span>
    </span>
</label>
