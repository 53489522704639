import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

import { BaseApi } from '@ata/utils';
import { ConfigService } from '@ata/shared/data-access/configs';

import { map } from 'rxjs/operators';

import { IRequestOTPDto } from '../dtos/request-otp.dto';
import { IVerifyOTPDto } from '../dtos/verify-otp.dto';
import { ILoginDto } from '../dtos/login.dto';
import { IAuthLogin } from '../interfaces/auth-login.interface';
import { AUTH_FEATURE_KEY } from '../states/auth.constants';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private api: BaseApi, private configService: ConfigService, private router: Router) {}

  /**
   * Request OTP
   *
   * @param payload IRequestOTP
   * @returns
   */
  public requestOTP = (payload: IRequestOTPDto): Observable<{ message: string; meta: any }> => {
    return this.api.post(`${this.configService.config.authEndpoint}/request-otp`, payload).pipe(map((response) => response.body));
  };

  /**
   * Verify OTP
   *
   * @param payload IVerifyOTP
   * @returns
   */
  public verifyOTP = (payload: IVerifyOTPDto): Observable<{ message: string }> => {
    return this.api.post(`${this.configService.config.authEndpoint}/verify-otp`, payload).pipe(map((response) => response.body));
  };

  /**
   * Login
   *
   * @param payload ILoginDto
   * @returns
   */
  public login = (payload: ILoginDto): Observable<IAuthLogin> => {
    return this.api.post(`${this.configService.config.systemEndpoint}/login`, payload).pipe(map((response) => response.body));
  };

  /**
   * Logout
   *
   * @returns
   */
   public logout = (): Observable<any> => {
    return this.api.post(`${this.configService.config.systemEndpoint}/logout`).pipe(map((response) => response.body));
  };

  /**
   * login check
   */
  isLogin(): boolean {
    const data = localStorage.getItem(`${AUTH_FEATURE_KEY}.token`);
    return data !== undefined && data !== null;
  }
}
