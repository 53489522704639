import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';

import { PrimeNgModule } from './primeng.module';
import { CustomUiModule } from './custom.module';

import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { TranslateModule } from '@ngx-translate/core';
import { NgxPermissionsModule } from 'ngx-permissions';

const angularModules = [CommonModule, FormsModule, ReactiveFormsModule, GoogleMapsModule];

const customModules = [PrimeNgModule, CustomUiModule, RecaptchaModule, RecaptchaFormsModule, TranslateModule, NgxPermissionsModule];

const modules = [...angularModules, ...customModules];

@NgModule({
  imports: modules,
  exports: modules,
})
export class UiCommonModule {}
