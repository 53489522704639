import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { PermissionsService } from './services/permissions.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionsGuard  {
  constructor(private router: Router, private permissionsService: PermissionsService) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    let pathSegment = route.url[0].path;
    pathSegment = pathSegment === 'cases' || pathSegment === 'workshops' || pathSegment === 'billings' ? pathSegment.slice(0, -1) : pathSegment;

    const haveAccess = this.permissionsService.checkCmsAccessControl('find', pathSegment);
    return haveAccess;
  }
}
