import { ICaseWorkshop } from '../interfaces/case-workshop.interface';
import { ICaseTowing } from '../interfaces/case-towing.interface';
import { CaseWorkshop } from './case-workshop.model';

export class CaseTowing implements ICaseTowing {
  id?: string;
  workshop?: ICaseWorkshop;
  preferredWorkshopName?: string;
  usePreferredWorkshop?: boolean;
  preferredWorkshopFullAddress?: string;
  preferredWorkshopLatitude?: number;
  preferredWorkshopLongitude?: number;

  constructor(caseTowing: CaseTowing) {
    if (caseTowing?.id) {
      this.id = caseTowing.id;
    }

    this.workshop = new CaseWorkshop(caseTowing?.workshop);
    this.preferredWorkshopName = caseTowing?.preferredWorkshopName || null;
    this.usePreferredWorkshop = caseTowing?.usePreferredWorkshop || false;
    this.preferredWorkshopFullAddress = caseTowing?.preferredWorkshopFullAddress || null;
    this.preferredWorkshopLatitude = caseTowing?.preferredWorkshopLatitude || 0;
    this.preferredWorkshopLongitude = caseTowing?.preferredWorkshopLongitude || 0;
  }
}
