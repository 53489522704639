export interface ICaseProvider {
  id: string;
  name: string;
  address1: string;
  address2: string;
  address3: string;
  postcode: string;
  city: string;
  state: string;
  telephone1: string;
  telephone2: string;
  email: string;
  providerType: string;
  created_by: Date;
  updated_by: Date;
}
