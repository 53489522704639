<i style="font-size: 1.5rem; color: #0DA193; cursor: pointer;" class="pi pi-info-circle" (click)="toggleOverlayPanel()"></i>

<div class="modal-background" [ngClass]="{'show': isOverlayPanelVisible}"></div>

<div class="overlay-container popup-container" *ngIf="isOverlayPanelVisible">
  <div class="content-container">
    <h6 class="ata-mb-3 ata-font-bold ata-text-xl ata-text-center secondary">{{ title }}</h6>
    <main style="color: black">
      <div class="ata-text-center">{{ description }}</div>            
      <div class="image-container">
        <div  class="photo-dimension" *ngFor="let image of images" style="text-align: center">
          <div class="ata-mb-2">
            <img style="width: 100%; height: 100%; border-radius: 8px;" [src]="image.src" [alt]="image.alt" />
            <div>{{ image.label }}</div>
          </div>    
        </div>     
      </div>       
    </main>
    <button style="margin-top: 10px" class="ata-btn ata-btn-primary ata-btn-block" (click)="toggleOverlayPanel($event)">Continue</button> 
  </div>
</div>