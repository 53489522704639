import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';

import * as AuthActions from './auth.actions';
import { AUTH_FEATURE_KEY } from './auth.constants';
import { Auth } from '../models/auth.model';

export interface State extends EntityState<Auth> {
  logIn: boolean;
  loggedIn: boolean;
  logOut: boolean;
  loggedOut: boolean;
  requestOTP: boolean;
  requestedOTP: boolean;
  verifyOTP: boolean;
  verifiedOTP: boolean;
  error?: any;
  jwt: string | null;
  otp: { phoneNumber: string; ttl: number; otp: string; requestedAt: string };
}

export interface AuthPartialState {
  readonly [AUTH_FEATURE_KEY]: State;
}

export const authAdapter: EntityAdapter<Auth> = createEntityAdapter<Auth>();

export const initialState: State = authAdapter.getInitialState({
  logIn: false,
  loggedIn: false,
  logOut: false,
  loggedOut: false,
  requestOTP: false,
  requestedOTP: false,
  verifyOTP: false,
  verifiedOTP: false,
  jwt: sessionStorage.getItem(`${AUTH_FEATURE_KEY}.jwt`),
  otp: {
    requestedAt: JSON.parse(localStorage.getItem(`${AUTH_FEATURE_KEY}.otp`))?.requestedAt,
    ttl: JSON.parse(localStorage.getItem(`${AUTH_FEATURE_KEY}.otp`))?.ttl,
    otp: JSON.parse(localStorage.getItem(`${AUTH_FEATURE_KEY}.otp`))?.otp,
    phoneNumber: JSON.parse(localStorage.getItem(`${AUTH_FEATURE_KEY}.otp`))?.phoneNumber,
  },
});

const authReducer = createReducer(
  initialState,
  on(AuthActions.getAuthOTPInit, (state) => ({ ...state, requestOTP: true, requestedOTP: false, error: null })),
  on(AuthActions.getAuthOTPSuccess, (state, { otp }) => ({ ...state, requestOTP: false, requestedOTP: true, error: null, otp })),
  on(AuthActions.getAuthOTPFailure, (state, { error }) => ({ ...state, requestOTP: false, requestedOTP: false, error })),

  on(AuthActions.verifyAuthOTPInit, (state) => ({ ...state, verifyOTP: true, verifiedOTP: false, error: null })),
  on(AuthActions.verifyAuthOTPSuccess, (state) => ({ ...state, verifyOTP: false, verifiedOTP: true, error: null })),
  on(AuthActions.verifyAuthOTPFailure, (state, { error }) => ({ ...state, verifyOTP: false, verifiedOTP: false, error })),

  on(AuthActions.requestAuthLoginInit, (state) => ({ ...state, logIn: true, loggedIn: false, requestedOTP: false, verifiedOTP: false, error: null })),
  on(AuthActions.requestAuthLoginSuccess, (state, { jwt }) => ({ ...state, logIn: false, loggedIn: true, jwt,  error: null })),
  on(AuthActions.requestAuthLoginFailure, (state, { error }) => ({ ...state, logIn: false, loggedIn: false, error })),

  on(AuthActions.requestAuthLogoutInit, (state) => ({ ...state, logOut: true, loggedOut: false, error: null })),
  on(AuthActions.requestAuthLogoutSuccess, (state) => ({ ...state, logOut: false, loggedOut: true, error: null })),
  on(AuthActions.requestAuthLogoutFailure, (state, { error }) => ({ ...state, logOut: false, loggedOut: false, error }))
);

export function reducer(state: State | undefined, action: Action) {
  return authReducer(state, action);
}
