import { Injectable } from '@angular/core';
import { Action, State, StateContext, StateToken } from '@ngxs/store';
import { catchError, tap, throwError } from 'rxjs';

import { ECaseVehicleType } from '../enums/case-vehicle-type.enum';
import { CasesService } from '../services/cases.service';
import { CancelCase, GetCase, RegisterCase, SetVehicleType, VerifyCase } from './cases.actions';

const CASE_STATE_TOKEN = new StateToken<any>('cases');

@State<any>({
  name: CASE_STATE_TOKEN,
  defaults: {
    entity: {
      loading: false,
      data: null
    },
    entities: {
      loading: false,
      data: []
    },
    ticketId: null,
    vehicleNo: null,
    vehicleType: ECaseVehicleType.car,
  },
})
@Injectable()
export class CasesStates {
  constructor(private _casesService: CasesService) {}

  @Action(RegisterCase)
  registerCase(ctx: StateContext<any>, action: RegisterCase) {
    return this._casesService.registerCase(action.caseData).pipe(
      tap((result) => {
        ctx.patchState({
          ticketId: result?.ticketId,
          vehicleNo: result?.vehicleNo
        });
      })
    );
  }

  @Action(GetCase)
  getCase(ctx: StateContext<any>, action: GetCase) {
    const states = ctx.getState();
    const { caseId } = action.params;

    ctx.setState({
      ...states,
      entity: {
        ...states.entity,
        loading: true,
      }
    });

    return this._casesService.getCaseById(caseId).pipe(
      tap((result) => {
        ctx.setState({
          ...states,
          entity: {
            ...states.entity,
            data: result,
            loading: false
          }
        });
      })
    );
  }

  @Action(VerifyCase)
  verifyCase(ctx: StateContext<any>, action: VerifyCase) {
    const states = ctx.getState();
    const { ticketId, vehicleNo } = action.params;

    ctx.setState({
      ...states,
      entity: {
        ...states.entity,
        loading: true,
      }
    });

    return this._casesService.verifyCaseTicket(ticketId, vehicleNo).pipe(
      tap((result) => {
        ctx.setState({
          ...states,
          entity: {
            ...states.entity,
            data: result,
            loading: false
          }
        });
      })
    );
  }

  @Action(CancelCase)
  cancelCase(ctx: StateContext<any>, action: CancelCase) {
    const states = ctx.getState();
    const { caseId, payload } = action.params;

    ctx.setState({
      ...states,
      entity: {
        ...states.entity,
        loading: true,
      }
    });

    return this._casesService.cancelCase(payload, caseId).pipe(
      tap(() => {
        ctx.setState({
          ...states,
          entity: {
            ...states.entity,
            data: null
          }
        });
      })
    );
  }

  @Action(SetVehicleType)
  setVehicleType(ctx: StateContext<any>, action: SetVehicleType) {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      vehicleType: action.vehicleType,
    });
  }
}