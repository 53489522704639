<p-messages severity="info" styleClass="custom-message" [closable]="true" *ngIf="showMessage">
    <ng-template pTemplate>
        <div class="flex justify-content-center align-content-center w-full">
            <button type="button" class="p-message-close-button mr-3 text-2xl" (click)="closeMessage()">&times;</button>
            
            <ng-container *ngIf="etiqaPlusFlag==='true'; else etiqa">
                <img src="assets/images/etiqaplusicon.svg" width="32" />
                <div class="ml-3 align-self-center link-text">Get 24/7 auto assist services with Etiqa+ app</div>
            </ng-container>
            <div class="ml-3 align-self-center">
                <a href="https://eti.qa/autodownload" target="_blank" pButton label="DOWNLOAD" class="p-button link-button p-2"></a>
            </div>
        </div>
    </ng-template>
</p-messages>

<div>
    <p-sidebar [showCloseIcon]="false" [(visible)]="sidebarToggle" modal="true">
        <div class="ata-px-3">
            <div class="ata-py-6 ata-border-b ata-border-slate-100">
                <img alt="eaac-logo" src="assets/images/EAAC.svg" />
            </div>
            <nav class="ata-flex ata-flex-col">
                <a href="javascript:;" (click)="reloadPage()" class="ata-flex ata-items-center ata-py-4">
                    <img class="ata-w-6 ata-h-6" alt="back to home" src="assets/icons/sidebar-home.svg" />
                    <span class="ata-pl-3">{{ 'LandingTopMenuText' | translate }}</span>
                </a>
                <div class="ata-border-b ata-border-slate-100 ata-w-full ata-h-px"></div>
            </nav>
        </div>
    </p-sidebar>

    <header role="banner" class="flex header">
        <div class="header-nav">
            <button
                pButton
                type="button"
                icon="pi pi-bars"
                class="p-button-rounded p-button-text p-button-lg"
                (click)="sidebarToggle = !sidebarToggle"
            ></button>
        </div>

        <a (click)="reloadPage()" class="header-logo">
            <img alt="eaac-logo" src="assets/images/EAAC.svg" />
        </a>

        <ul class="header-toolbar float-right">
            <li>
                <button pButton pRipple type="button" icon="pi pi-phone" class="p-button-rounded p-button-danger" (click)="showHotlineDialog = true"></button>
            </li>
        </ul>
    </header>
</div>

<section role="main" class="main">
    <router-outlet></router-outlet>
</section>

<p-dialog
    [(visible)]="showHotlineDialog"
    [position]="'bottom'"
    [modal]="true"
    [draggable]="false"
    [resizable]="false"
    [closable]="true"
    [closeIcon]="false"
    [showHeader]="false"
    [dismissableMask]="true"
>
    <div class="ata-flex ata-items-center">
        <div class="ata-flex-grow">
            <h4 class="ata-font-semibold ata-mb-1">Malaysia</h4>
            <p class="ata-text-sm ata-text-slate-500">1-800-88-6491</p>
        </div>
        <div class="ata-pl-6">
            <button type="button" pButton icon="pi pi-phone" class="p-button-rounded p-button-danger" (click)="openCallPopup('1-800-88-6491')"></button>
        </div>
    </div>

    <p-divider></p-divider>

    <div class="ata-flex ata-items-center">
        <div class="ata-flex-grow">
            <h4 class="ata-font-semibold ata-mb-1">Singapore, Thailand & Brunei</h4>
            <p class="ata-text-sm ata-text-slate-500">+603-2296 8600</p>
        </div>
        <div class="ata-pl-6">
            <button type="button" pButton icon="pi pi-phone" class="p-button-rounded p-button-danger" (click)="openCallPopup('+603-2296 8600')"></button>
        </div>
    </div>
</p-dialog>

<ng-template #etiqa>
    <img src="assets/images/smile-app.svg" width="32" />
    <div class="ml-3 align-self-center link-text">Get 24/7 auto assist services with Etiqa SMILE app</div>
</ng-template>
