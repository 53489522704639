import { Injectable } from '@angular/core';
import { Action, State, StateContext, StateToken } from '@ngxs/store';

import { ECaseVehicleType } from '@ata/shared/data-access/cases';

import { AtauAction } from './apps.actions';
import { APP_STATE_NAME } from './apps.constants';

export const APP_STATE_TOKEN = new StateToken<any>(APP_STATE_NAME);

@State<any>({
  name: APP_STATE_TOKEN,
  defaults: {
    atau: {
      assessment: {
        activeStep: 0,
        destinationType: null,
        service: null,
        vehicleType: ECaseVehicleType.car,
        location: null,
        destination: null,
        workshop: null,
        formData: null,
        questionnaires: null,
        vehicleNo: null,
        ticketId: null,
        hasShownServiceDelay: false,
      },
      rating: null,
      duplicateCase: null
    }
  }
})
@Injectable()
export class AppsStates {
  @Action(AtauAction.SetService)
  atauSetService(ctx: StateContext<any>, action: AtauAction.SetService) {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      atau: {
        ...state.atau,
        assessment: {
          ...state.atau.assessment,
          service: action.service
        }
      }
    });
  }

  @Action(AtauAction.SetVehicleType)
  atauSetVehicleType(ctx: StateContext<any>, action: AtauAction.SetVehicleType) {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      atau: {
        ...state.atau,
        assessment: {
          ...state.atau.assessment,
          vehicleType: action.type
        }
      }
    });
  }

  @Action(AtauAction.SetLocation)
  atauSetLocation(ctx: StateContext<any>, action: AtauAction.SetLocation) {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      atau: {
        ...state.atau,
        assessment: {
          ...state.atau.assessment,
          location: action.location,
        }
      }
    });
  }

  @Action(AtauAction.SetDestination)
  atauSetDestination(ctx: StateContext<any>, action: AtauAction.SetDestination) {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      atau: {
        ...state.atau,
        assessment: {
          ...state.atau.assessment,
          destination: action.destination,
        }
      }
    });
  }

  @Action(AtauAction.SetWorkshop)
  atauSetWorkshop(ctx: StateContext<any>, action: AtauAction.SetWorkshop) {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      atau: {
        ...state.atau,
        assessment: {
          ...state.atau.assessment,
          workshop: action.workshop,
        }
      }
    });
  }

  @Action(AtauAction.SetFormData)
  atauSetFormData(ctx: StateContext<any>, action: AtauAction.SetFormData) {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      atau: {
        ...state.atau,
        assessment: {
          ...state.atau.assessment,
          formData: action.formData,
        }
      }
    });
  }

  @Action(AtauAction.SetActiveStep)
  atauSetActiveStep(ctx: StateContext<any>, action: AtauAction.SetActiveStep) {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      atau: {
        ...state.atau,
        assessment: {
          ...state.atau.assessment,
          activeStep: action.index,
        }
      }
    });
  }

  @Action(AtauAction.SetDestinationType)
  atauSetDestinationType(ctx: StateContext<any>, action: AtauAction.SetDestinationType) {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      atau: {
        ...state.atau,
        assessment: {
          ...state.atau.assessment,
          destinationType: action.type,
        }
      }
    });
  }

  @Action(AtauAction.SetQuestionnaires)
  atauSetQuestionnaires(ctx: StateContext<any>, action: AtauAction.SetQuestionnaires) {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      atau: {
        ...state.atau,
        assessment: {
          ...state.atau.assessment,
          questionnaires: action.questionnaires,
        }
      }
    });
  }

  @Action(AtauAction.SetOverridingService)
  atauSetOverridingService(ctx: StateContext<any>, action: AtauAction.SetOverridingService) {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      atau: {
        ...state.atau,
        assessment: {
          ...state.atau.assessment,
          overridingService: action.overridingService,
        }
      }
    });
  }

  @Action(AtauAction.SetDuplicateCase)
  atauSetDuplicateCase(ctx: StateContext<any>, action: AtauAction.SetDuplicateCase) {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      atau: {
        ...state.atau,
        duplicateCase: action.caseData,
      }
    });
  }

  @Action(AtauAction.SetRatingMeta)
  atauSetRatingMeta(ctx: StateContext<any>, action: AtauAction.SetRatingMeta) {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      atau: {
        ...state.atau,
        rating: action.ratingMeta,
      }
    });
  }

  @Action(AtauAction.SetUTMSource)
  atauSetUTMSource(ctx: StateContext<any>, action: AtauAction.SetUTMSource) {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      atau: {
        ...state.atau,
        utmSource: action.utmSource,
      }
    });
  }

  @Action(AtauAction.SetHasShownServiceDelay)
  atauSetHasShownServiceDelay(ctx: StateContext<any>, action: AtauAction.SetHasShownServiceDelay) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      atau: {
        ...state.atau,
        assessment: {
          ...state.atau.assessment,
          hasShownServiceDelay: action.hasShownServiceDelay,
        },
      },
    });
  }
}
