import { Component } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseComponent } from '@ata/utils';

@Component({
  selector: 'ata-input-phone',
  templateUrl: './input-phone.component.html',
  styleUrls: ['./input-phone.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: InputPhoneComponent,
    },
  ],
})
export class InputPhoneComponent extends BaseComponent implements ControlValueAccessor {
  public countryCodes: Array<{ label: string; value: string }> = [
    {
      label: '+60',
      value: '+60',
    },
    {
      label: '+65',
      value: '+65',
    },
    {
      label: '+66',
      value: '+66',
    },
    {
      label: '+673',
      value: '+673',
    },
  ];

  phoneNumber = '';

  countryCode = this.countryCodes[0].value;

  disabled = false;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange = (value: string) => {
    //
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched = () => {
    //
  };

  onCountryCodeChange(value: string): void {
    if (this.disabled) {
      // return;
    }
    this.countryCode = value;
    this.onChange(`${this.countryCode}${this.phoneNumber}`?.substring(1));
  }

  onPhoneNumberChange(value: string): void {
    if (this.disabled) {
      // return;
    }
    this.phoneNumber = value;
    this.onChange(`${this.countryCode}${this.phoneNumber}`?.substring(1));
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: string | { value: string; disabled: boolean }): void {
    if (value) {
      if (typeof value === 'string') {
        this._formatValue(value);
      } else {
        this._formatValue(value.value);
        this.disabled = value.disabled;
      }
    }
  }

  markAsTouched(): void {
    this.onTouched();
  }

  private _formatValue = (value: string) => {
    const cc = this.countryCodes.find((cc) => value?.substring(0, 3).includes(cc?.value?.substring(1)));
    const ccc = cc ? cc : this.countryCodes[0];
    const pn = value?.substring(ccc?.value?.substring(1).length);

    this.phoneNumber = pn;
    this.countryCode = ccc.value;
  };
}
