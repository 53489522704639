import { createAction, props } from '@ngrx/store';
import { ILoginDto } from '../dtos/login.dto';

import { IRequestOTPDto } from '../dtos/request-otp.dto';
import { IVerifyOTPDto } from '../dtos/verify-otp.dto';

export const getAuthOTPInit = createAction('[Auth/API] Get Auth OTP init', props<{ payload: IRequestOTPDto }>());

export const getAuthOTPSuccess = createAction('[Auth/API] Get Auth OTP Success', props<{ otp: any }>());

export const getAuthOTPFailure = createAction('[Auth/API] Get Auth OTP Failure', props<{ error: any }>());

export const verifyAuthOTPInit = createAction('[Auth/API] Verify Auth OTP init', props<{ payload: IVerifyOTPDto }>());

export const verifyAuthOTPSuccess = createAction('[Auth/API] Verify Auth OTP Success');

export const verifyAuthOTPFailure = createAction('[Auth/API] Verify Auth OTP Failure', props<{ error: any }>());

export const requestAuthLoginInit = createAction('[Auth/API] Request Auth Login init', props<{ payload: ILoginDto }>());

export const requestAuthLoginSuccess = createAction('[Auth/API] Request Auth Login Success', props<{ jwt: string }>());

export const requestAuthLoginFailure = createAction('[Auth/API] Request Auth Login Failure', props<{ error: any }>());

export const requestAuthLogoutInit = createAction('[Auth/API] Request Auth Logout init');

export const requestAuthLogoutSuccess = createAction('[Auth/API] Request Auth Logout Success');

export const requestAuthLogoutFailure = createAction('[Auth/API] Request Auth Logout Failure', props<{ error: any }>());
