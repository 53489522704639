import { ErrorATA } from './error-ata';

/** this part for Angular Front End Mapping to Message*/
export class ErrorAtaMap {
  //add every new line here
  //Format: Code, Title, Message
  private static errorMap: Map<string, ErrorAtaCustomMessage> = new Map([
    [ErrorATA.MISSING_WORKSHOP_INFO, { title: 'Missing Info', desc: 'Missing workshop details' }],
  ]);

  static translate(errorAtaCode: string): ErrorAtaCustomMessage {
    return ErrorAtaMap.errorMap.get(errorAtaCode);
  }
}

export interface ErrorAtaCustomMessage {
  title: string;
  desc: string;
}
