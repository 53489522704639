import { APP_INITIALIZER, ErrorHandler, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EntityCollectionReducerMethodsFactory, EntityDataModule, PersistenceResultHandler } from '@ngrx/data';
import { SocketIoModule } from 'ngx-socket-io';
import { NgxsModule } from '@ngxs/store';
import { NgxsStoragePluginModule, StorageOption } from '@ngxs/storage-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';

import { Config, ConfigService } from '@ata/shared/data-access/configs';
import { PermissionsService } from '@ata/shared/data-access/permissions';
import { AppsStates } from '@ata/shared/data-access/apps';

import { GlobalErrorHandler } from './handlers/global-error.handler';
import { AtaHttpInterceptor } from './interceptors/ata-http.interceptor';
import { CustomPersistenceResultHandler } from './handlers/custom-persistence-result.handler';
import { CustomEntityCollectionReducerMethodsFactory } from './factories/custom-entity-collection-reducer-methods.factory';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/');
}

export function loadPermissions(configService: ConfigService, permissionsService: PermissionsService) {
  return () => (configService.config.loadPermission ? permissionsService.loadAllRolesPermissions(configService.config.systemEndpoint) : null);
}

export function throwIfAlreadyLoaded(parentModule: unknown, moduleName: string) {
  if (parentModule) {
    throw new Error(`${moduleName} has already been loaded. Import ${moduleName} in the AppModule only.`);
  }
}

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SocketIoModule.forRoot({ url: '' }),
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument(),
    EntityDataModule.forRoot({}),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    NgxsModule.forRoot([]),
    NgxsStoragePluginModule.forRoot({
      storage: StorageOption.SessionStorage,
      key: AppsStates,
    }),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: false, // TODO: replace it with environment flag
    }),
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(config: Config): ModuleWithProviders<any> {
    return {
      ngModule: CoreModule,
      providers: [
        {
          provide: ErrorHandler,
          useClass: GlobalErrorHandler,
        },
        {
          provide: PersistenceResultHandler,
          useClass: CustomPersistenceResultHandler,
        },
        {
          provide: EntityCollectionReducerMethodsFactory,
          useClass: CustomEntityCollectionReducerMethodsFactory,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AtaHttpInterceptor,
          multi: true,
        },
        {
          provide: APP_INITIALIZER,
          useFactory: loadPermissions,
          deps: [ConfigService, PermissionsService],
          multi: true,
        },
        {
          provide: Config,
          useValue: config,
        },
      ],
    };
  }
}
