import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ConfigService } from '@ata/shared/data-access/configs';

import { CASE_ENTITY_NAME } from '../case.constant';
import { Case } from '../models/case.model';

@Injectable()
export class CaseDataService extends DefaultDataService<Case> {
  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator, private configService: ConfigService) {
    super(CASE_ENTITY_NAME, http, httpUrlGenerator);
  }

  getById(key: string | number): Observable<Case> {
    return this.http.get(`${this.configService.config.cmsEndPoint}/cases/${key}`).pipe(map((result: any) => result));
  }
  
  getWithQuery(queryParams: any): Observable<any> {
    let params = new HttpParams({ fromObject: queryParams });
    const count = queryParams?._count;

    params = params.delete('_count');

    const streams = [this.http.get(`${this.configService.config.cmsEndPoint}/cases`, { params })];

    if (count) {
      streams.push(this.http.get(`${this.configService.config.cmsEndPoint}/cases/count`, { params }));
    }

    return forkJoin(streams).pipe(
      map(([result, count]) => {
        return {
          data: result,
          count,
        };
      })
    );
  }
}
