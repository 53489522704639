<ul class="ata-flex ata-items-center">
    <ng-container *ngFor="let step of steps; let stepIndex = index; let last = last; let first = first;">
        <li class="ata-w-full ata-relative" *ngIf="step.show">
            <div class="ata-w-1/2 ata-absolute ata-top-4 ata-left-0 ata-z-10 ata-border ata-border-slate-200" *ngIf="!first"></div>
            <div class="ata-flex ata-flex-col ata-items-center ata-relative ata-z-20">
                <div
                    class="ata-flex ata-items-center ata-justify-center ata-w-9 ata-h-9 ata-border-2 ata-rounded-full ata-mb-2 ata-font-bold"
                    [ngClass]="{
                        'ata-text-slate-300 ata-border-slate-300 ata-bg-white': stepIndex !== activeStep,
                        'ata-text-primary-50 ata-border-primary-500 ata-bg-primary-500': stepIndex === activeStep
                    }"
                >
                    {{ step.bulletLabel }}
                </div>
                <div
                    class="ata-font-medium ata-text-xs"
                    [ngClass]="{
                        'ata-text-slate-300': stepIndex !== activeStep
                    }"
                >
                    {{ step.label }}
                </div>
            </div>
            <div class="ata-w-1/2 ata-absolute ata-top-4 ata-right-0 ata-z-10 ata-border ata-border-slate-200" *ngIf="!last"></div>
        </li>
    </ng-container>
</ul>
