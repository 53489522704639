import { Component, Input } from '@angular/core';

interface IStep {
  label: string;
  bulletLabel: string;
  show: boolean;
  active: boolean;
}

@Component({
  selector: 'ata-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
})
export class StepperComponent {
  @Input() steps: IStep[] = [];

  @Input() activeStep = 0;
}
