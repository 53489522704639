import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseComponent } from '@ata/utils';
import { BehaviorSubject } from 'rxjs';
interface IValue {
  formattedAddress?: string | null;
  name?: string | null;
  latlng?: any;
  additionalInfo?: string | null;
  source?: string | null;
}

@Component({
  selector: 'ata-input-place-autocomplete',
  templateUrl: './input-place-autocomplete.component.html',
  styleUrls: ['./input-place-autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: InputPlaceAutocompleteComponent,
    },
  ],
})
export class InputPlaceAutocompleteComponent extends BaseComponent implements ControlValueAccessor, AfterViewInit {
  public value: IValue = {
    formattedAddress: null,
    name: null,
    latlng: { lat: 0, lng: 0 },
    additionalInfo: null,
    source: null
  };

  public disabled = false;

  public manualInput = false;

  public isGeolocating$ = new BehaviorSubject(false);

  @Input() public placeholder = 'Your current location';

  @Input() public getLocationButton = true;

  @Input() public additionalInfo = true;

  @Input() public readonly = false;

  @ViewChild('mapAutocomplete', { static: false }) public mapAutocomplete!: ElementRef;

  @Output() public placeChange = new EventEmitter<IValue>();

  @Output() locationDisabledError = new EventEmitter<any>();

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange = (value: IValue) => {
    // empty for now
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched = () => {
    // empty for now
  };

  onPlaceInputChange(latlng: google.maps.LatLng, formattedAddress: string, name: string, source: string): void {
    if (this.disabled) {
      // return;
    }

    this.value.latlng = latlng.toJSON();
    this.value.formattedAddress = formattedAddress;
    this.value.name = name;
    this.value.source = source;

    this.onChange({ ...this.value });
    this.placeChange.emit({ ...this.value });

    this.manualInput = true;
  }

  onAdditionalInfoInputChange(event: Event): void {
    if (this.disabled) {
      // return;
    }

    this.value.additionalInfo = (event.target as HTMLInputElement).value;
    this.onChange({ ...this.value });
    this.placeChange.emit({ ...this.value });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: IValue): void {
    if (value && Object.keys(value).length > 0) {
      this.value = { ...value };
    }
  }

  markAsTouched(): void {
    this.onTouched();
  }

  ngAfterViewInit(): void {
    if (this.readonly) {
      return;
    }

    this._initAutocomplete();
  }

  public clearValue = () => {
    this.value = {
      formattedAddress: null,
      latlng: null,
      additionalInfo: this.value.additionalInfo
    };
    
    this.onChange({ ...this.value });

    this.manualInput = false;
  }

  public getCurrentLocation = () => {
    if (navigator.geolocation) {
      this.isGeolocating$.next(true);

      navigator.geolocation.getCurrentPosition(() => { false }, () => { false }, {});
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latlng = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
          const geocoder = new google.maps.Geocoder();

          geocoder.geocode({ location: latlng }, (results: google.maps.GeocoderResult[] | null, status: google.maps.GeocoderStatus) => {
            if (results && status === google.maps.GeocoderStatus.OK) {
              const lat = results[0].geometry.location.lat() || 0;
              const lng = results[0].geometry.location.lng() || 0;
              const latlng: google.maps.LatLng = new google.maps.LatLng(lat, lng);
              const formattedAddress = results[0].formatted_address || '';
              const name = formattedAddress;

              this.onPlaceInputChange(latlng, formattedAddress, name, 'currentLocation');
              this.isGeolocating$.next(false);
            }
          });
        },
        (error) => {
          this.isGeolocating$.next(false);
          this.locationDisabledError.emit(error);
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 10000,
        }
      );
    } else {
      this.isGeolocating$.next(false);
      throw Error('navigator.geolocation is not available');
    }
  };

  private _initAutocomplete = () => {
    const autocomplete = new google.maps.places.Autocomplete(this.mapAutocomplete.nativeElement, {
      componentRestrictions: { country: ['MY', 'KH', 'TH', 'SG', 'BN'] }, // Set initial restriction to Malaysia, Cambodia, Thailand, Singapore, Brunei
      fields: ['formatted_address', 'geometry', 'name'],
    });

    autocomplete.addListener('place_changed', () => {
      if (autocomplete.getPlace().geometry?.location) {
        const lat = autocomplete.getPlace().geometry?.location?.lat() || 0;
        const lng = autocomplete?.getPlace().geometry?.location?.lng() || 0;
        const latlng: google.maps.LatLng = new google.maps.LatLng(lat, lng);
        const formattedAddress = autocomplete.getPlace().formatted_address || '';
        const name = autocomplete.getPlace().name || '';
        // append name for better location visibility
        const fullAddress = name.length > 1 ? (formattedAddress.includes(name) ? formattedAddress:  `${name}, ${formattedAddress}`): formattedAddress;

        this.onPlaceInputChange(latlng, formattedAddress, fullAddress,'autocomplete');
      }
    });
  };
}
