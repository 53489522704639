import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AUTH_FEATURE_KEY } from '@ata/shared/data-access/auth';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import Swal from 'sweetalert2/dist/sweetalert2';

import { AppConfig } from './app-config';
import { AppService } from './app.service';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private readonly userDataListener: EventEmitter<AdminUserLoginData> = new EventEmitter();

  constructor(private router: Router, private http: HttpClient, private appService: AppService) {}

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  listen(): Observable<AdminUserLoginData> {
    return this.userDataListener.asObservable();
  }

  login(requestData: Login) {
    const url = this.appService.config.cmsEndPoint + '/auth';

    return this.http.post(url + '/local', requestData, this.httpOptions);
  }

  loginAd(requestData: Login) {
    const url = this.appService.config.systemEndpoint + '/login';

    return this.http.post(url, requestData, this.httpOptions);
  }

  /**
   * simple login check. to be strengthen.
   */
  isLogin(): boolean {
    const data = localStorage.getItem(AppConfig.KEY_LOGIN);
    return data !== undefined && data !== null;
  }

  /**
   * Reading the login response and convert into object to store it.
   * @param user
   */
  storeUserData(response: any) {
    const data: AdminUserLoginData = {
      id: response.user.id,
      username: response.user.username,
      lastLoginDate: response.user.lastLogin,
      hash: response.hash,
      role: response.user.role.type,
      email: response.user.email,
      name: response.user.displayName,
      token: response.jwt,
    };

    // sessionStorage.setItem(`${AUTH_FEATURE_KEY}.token`, response.jwt);
    // localStorage.setItem(`${AUTH_FEATURE_KEY}.token`, response.jwt);
    localStorage.setItem(AppConfig.KEY_LOGIN, JSON.stringify(data));
    this.userDataListener.emit(data);
  }

  /** retrieve the login data from the local storage */
  getUserData(): AdminUserLoginData {
    const data: AdminUserLoginData = JSON.parse(localStorage.getItem('dashboard-login'));
    return data;
  }

  /** get the authentication token for any http call
   * to be used by http interceptor mostly
   */
  getAuthToken(): string {
    return JSON.parse(localStorage.getItem(AppConfig.KEY_LOGIN))?.token;
  }

  logout(): void {
    const url = this.appService.config.systemEndpoint + '/logout';
    const userDetails = JSON.parse(localStorage.getItem(AppConfig.KEY_LOGIN));

    if (userDetails && !userDetails.token) {
      this.http.post(url, { token: userDetails.token }, this.httpOptions).subscribe((error) => {
        Swal.fire(
          'This username is currently in use',
          'Your username can only be used on 1 device. If you continue to log in, the access from the other device will be automatically signed out',
          'error'
        );
      });
    }
    if (userDetails && userDetails.id) {
      this.http.post(url, { id: userDetails.id }, this.httpOptions).subscribe((error) => {
        Swal.fire(
          'Logged out successfully',
          'You have been logged out successfully',
          'success'
        );
      })
    }

    localStorage.removeItem(AppConfig.KEY_LOGIN);
    this.userDataListener.emit(null);
    this.router.navigateByUrl('/');
  }
}

export interface AdminUserLoginData {
  id: string;
  username: string;
  pfNo?: string;
  token: string;
  lastLoginDate: string;
  hash: string;
  role: string;
  email: string;
  name: string;
}

export interface Login {
  username: string;
  password: string;
}
