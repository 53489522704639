import { IPermissions } from '../interfaces/permissions.interface';
import { Role } from './permissions-role.model';

export class Permissions implements IPermissions {
  hashedPermissions: string;
  allPermissions: Role;

  constructor(permissions: IPermissions) {
    this.hashedPermissions = permissions?.hashedPermissions;
    this.allPermissions = new Role(permissions?.allPermissions);
  }
}
