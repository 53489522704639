import { Component, Input } from '@angular/core';

@Component({
  selector: 'ata-info-popup',
  templateUrl: './ata-info-popup.component.html',
  styleUrls: ['./ata-info-popup.component.scss']
})
export class AtaInfoPopupComponent {
  @Input() title: string = '';
  @Input() description: string = '';
  @Input() images: { src: string; label: string }[] = [];

  isOverlayPanelVisible: boolean = false;

  toggleOverlayPanel() {
    this.isOverlayPanelVisible = !this.isOverlayPanelVisible;
  }
}
