import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CASES_FEATURE_KEY, CasesState, casesAdapter } from './cases.reducer';

// Lookup the 'Cases' feature state managed by NgRx
export const getCasesState = createFeatureSelector<CasesState>(CASES_FEATURE_KEY);

const { selectAll, selectEntities } = casesAdapter.getSelectors();

export const getCasesCount = createSelector(getCasesState, (state: CasesState) => state.count);

export const getCasesBlob = createSelector(getCasesState, (state: CasesState) => state.blob);

export const getLoading = createSelector(getCasesState, (state: CasesState) => state.loading);

export const getCasesExporting = createSelector(getCasesState, (state: CasesState) => state.exporting);

export const getVerifying = createSelector(getCasesState, (state: CasesState) => state.verifying);

export const getCasesError = createSelector(getCasesState, (state: CasesState) => state.error);

export const getCases = createSelector(getCasesState, (state: CasesState) => selectAll(state));

export const getCase = createSelector(getCasesState, (state: CasesState) => selectEntities(state));

export const getCasesSelectedId = createSelector(getCasesState, (state: CasesState) => state.selectedId);
export const getCasesSelected = createSelector(getCase, getCasesSelectedId, (cases, selectedId) => (selectedId ? cases[selectedId] : undefined));

export const getCasesDuplicatedId = createSelector(getCasesState, (state: CasesState) => state.duplicatedId);
export const getCasesDuplicated = createSelector(getCase, getCasesDuplicatedId, (cases, duplicatedId) => (duplicatedId ? cases[duplicatedId] : undefined));

export const getCasesCreatedId = createSelector(getCasesState, (state: CasesState) => state.createdId);
export const getCasesCreated = createSelector(getCase, getCasesCreatedId, (cases, createdId) => (createdId ? cases[createdId] : undefined));

export const getCasesCancelledId = createSelector(getCasesState, (state: CasesState) => state.cancelledId);
export const getCasesCancelled = createSelector(getCase, getCasesCancelledId, (cases, cancelledId) => (cancelledId ? cases[cancelledId] : undefined));

export const getCaseUpdating = createSelector(getCasesState, (state: CasesState) => state.updating);
export const getCaseUpdatedId = createSelector(getCasesState, (state: CasesState) => state.updatedId);
export const getCaseUpdated = createSelector(getCase, getCaseUpdatedId, (cases, updatedId) => (updatedId ? cases[updatedId] : undefined));

export const getVerificationMeta = createSelector(getCasesState, (state: CasesState) => state.verificationMeta);
export const getCasesVerifiedId = createSelector(getCasesState, (state: CasesState) => state.verifiedId);
export const getCasesVerifiedCase = createSelector(getCase, getCasesVerifiedId, (cases, verifiedId) => (verifiedId ? cases[verifiedId] : undefined));
