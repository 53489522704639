import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'ata-jumbotron',
  templateUrl: './jumbotron.component.html',
  styleUrls: ['./jumbotron.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JumbotronComponent {
  @Input() title!: string;

  @Input() subtitle!: string;

  @Input() imageUrl!: string;

  public get backgroundImage(): string {
    return this.sanitizer.bypassSecurityTrustStyle(`url(${this.imageUrl})`) as string;
  }

  constructor(private sanitizer: DomSanitizer) {}
}
