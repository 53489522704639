import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ata-loading-block',
  templateUrl: './loading-block.component.html',
  styleUrls: ['./loading-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingBlockComponent {
  @Input() loading = false;
}