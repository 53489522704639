import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ata-checkbox-box',
  templateUrl: './checkbox-box.component.html',
  styleUrls: ['./checkbox-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxBoxComponent {
  @Input() label!: string;

  @Input() iconPath!: string;

  @Input() tag!: string;

  @Input() tagColor!: 'success' | 'warning' | 'danger' | 'info';
}
