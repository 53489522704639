import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2/dist/sweetalert2';
import {DataServiceError} from "@ngrx/data";

interface CustomError { statusCode: number }

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private spinner: NgxSpinnerService) {}

  handleError(error: (Error | HttpErrorResponse) & CustomError) {
    //first thing dismiss any NgxSpinner loading status
    this.spinner.hide();

    // forming the message. in case the error isn't handled before..
    let errorMsg;
    let errorTitle;
    if (error instanceof HttpErrorResponse) {
      errorTitle = error.statusText;
      errorMsg = error?.error?.message ?? error?.message;
      
    } else if (error instanceof DataServiceError) {
      errorMsg = error?.error?.message ?? error?.message;
    } else {
      errorMsg = error;
    }

    console.error('[Global Error Handler]', errorMsg, error);

    if (error.statusCode !== 409) {
      Swal.fire({
        icon: 'error',
        title: errorTitle ?? 'Error Message',
        text: errorMsg,
        showConfirmButton: true,
      });
    }
  }
}
