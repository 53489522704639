import { IConfigFirebase } from '../interfaces/config-firebase.interface';

export class ConfigFirebase implements IConfigFirebase {
  apiKey?: string;
  authDomain?: string;
  projectId?: string;
  storageBucket?: string;
  messagingSenderId?: string;
  appId?: string;
  measurementId?: string;

  constructor(configFirebase: ConfigFirebase) {
    this.apiKey = configFirebase?.apiKey;
    this.authDomain = configFirebase?.authDomain;
    this.projectId = configFirebase?.projectId;
    this.storageBucket = configFirebase?.storageBucket;
    this.messagingSenderId = configFirebase?.messagingSenderId;
    this.appId = configFirebase?.appId;
    this.measurementId = configFirebase?.measurementId;
  }
}
