import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EntityDataService, EntityDefinitionService } from '@ngrx/data';

import { CASE_ENTITY_NAME } from './case.constant';
import { caseMetadata } from './case-metadata';
import { CaseDataService } from './services/case-data.service';

@NgModule({
  imports: [CommonModule],
  providers: [CaseDataService],
})
export class CasesDataAccessModule {
  constructor(entityDefinitionService: EntityDefinitionService, entityDataService: EntityDataService, addonDataService: CaseDataService) {
    entityDefinitionService.registerMetadataMap(caseMetadata);
    entityDataService.registerService(CASE_ENTITY_NAME, addonDataService);
  }
}
