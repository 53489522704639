<div class="p-inputgroup">
    <span class="p-inputgroup-addon ata-rounded-r-none ata-shrink-0 ata-border-slate-300">
        <p-dropdown
            [options]="countryCodes"
            optionLabel="label"
            optionValue="value"
            [(ngModel)]="countryCode"
            [disabled]="disabled"
            (onChange)="onCountryCodeChange($event.value)"
        ></p-dropdown>
    </span>
    <input
        class="ata-input ata-rounded-l-none p-inputtext border-noround-left"
        placeholder="123456789"
        type="number"
        [(ngModel)]="phoneNumber"
        [disabled]="disabled"
        (change)="onPhoneNumberChange($event.target.value)"
    />
</div>
