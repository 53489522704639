import { Component, Input, Output, EventEmitter, ChangeDetectorRef, SimpleChanges } from '@angular/core';

@Component({
  selector: 'ata-alert-popup',
  templateUrl: './ata-alert-popup.component.html',
  styleUrls: ['./ata-alert-popup.component.scss']
})
export class AtaAlertPopupComponent {
  @Input() title: string = '';
  @Input() descriptionText: string = '';
  @Input() confirmationText: string = '';
  @Input() confirmButtonText: string = 'Confirm';
  @Input() cancelButtonText: string = 'Cancel';
  @Input() showPopup: boolean = false;
  @Output() confirmClicked: EventEmitter<void> = new EventEmitter<void>();  
  @Output() cancelClicked: EventEmitter<void> = new EventEmitter<void>();  

  constructor(private cdr: ChangeDetectorRef) {}

  onCancel() {    
    this.cancelClicked.emit();
  }

  onConfirmClicked() {    
    this.confirmClicked.emit();
  }
}
