import { ICaseCustomer } from '../interfaces/case-customer.interface';

export class CaseCustomer implements ICaseCustomer {
  id?: string;
  name: string;
  phoneNo: string;
  vehicleNo: string;
  created_by: string;
  updated_by: string;

  constructor(caseCustomer: CaseCustomer) {
    if (caseCustomer?.id) {
      this.id = caseCustomer.id;
    }

    this.name = caseCustomer?.name || null;
    this.phoneNo = caseCustomer?.phoneNo || null;
    this.vehicleNo = caseCustomer?.vehicleNo || null;
    this.created_by = caseCustomer?.created_by || null;
    this.updated_by = caseCustomer?.updated_by || null;
  }
}
