import { IConfigFirebase } from './config-firebase.interface';

export interface IConfig {
  local: boolean;
  sit: boolean;
  uat: boolean;
  prod: boolean;
  environment: string;
  authEndpoint: string;
  systemEndpoint: string;
  systemEndpointV2: string;
  commonEndpoint: string;
  socketEndpoint: string;
  cmsEndPoint: string;
  webEndpoint: string;
  smsTemplate: string;
  dashboardEndpoint: string;
  dashboardEndpointV2: string,
  providerEndpoint: string;
  minioEndpoint: string;
  firebase: IConfigFirebase;
  recaptchaSiteKey: string;
  loadPermission: boolean;
}

export interface IConfigFile {
  isEtiqaPlus: boolean;
}
