import { ICaseEvent } from '../interfaces/case-event.interface';

export class CaseEvent implements ICaseEvent {
  id?: string;
  dateTime: Date;
  description: string;
  cLat: number;
  cLong: number;

  constructor(caseEvent: CaseEvent) {
    if (caseEvent?.id) {
      this.id = caseEvent.id;
    }

    this.dateTime = caseEvent?.dateTime || null;
    this.description = caseEvent?.description || null;
    this.cLat = caseEvent?.cLat || 0;
    this.cLong = caseEvent?.cLong || 0;
  }
}
