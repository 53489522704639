import { ICaseService } from '../interfaces/case-service.interface';

export class CaseService implements ICaseService {
  id?: string;
  service: string;
  type: string;
  parent: string;
  created_by: string;
  updated_by: string;

  constructor(caseService: CaseService) {
    if (caseService?.id) {
      this.id = caseService.id;
    }

    this.service = caseService?.service || null;
    this.type = caseService?.type || null;
    this.parent = caseService?.parent || null;
    this.created_by = caseService?.created_by || null;
    this.updated_by = caseService?.updated_by || null;
  }
}
