const getDistanceMatrix = (service: google.maps.DistanceMatrixService, data: google.maps.DistanceMatrixRequest) =>
  new Promise((resolve, reject) => {
    service.getDistanceMatrix(data, (response, status) => {
      if (status === 'OK') {
        resolve(response);
      } else {
        reject(response);
      }
    });
  });

export const getDistance = async (location: google.maps.LatLngAltitudeLiteral, destination: google.maps.LatLngAltitudeLiteral) => {
  const locationLatLng = new google.maps.LatLng(location);
  const destinationLatLng = new google.maps.LatLng(destination);
  const service = new google.maps.DistanceMatrixService();
  const results = await getDistanceMatrix(service, {
    origins: [locationLatLng],
    destinations: [destinationLatLng],
    travelMode: google.maps.TravelMode.DRIVING,
    unitSystem: google.maps.UnitSystem.METRIC,
  });
  
  return (results as any).rows[0].elements[0];
};
