import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BaseApi {
  private headers: HttpHeaders = new HttpHeaders();

  constructor(private http: HttpClient) {}

  public get(path: string, params?: HttpParams): Observable<HttpResponse<any>> {
    return this.http
      .get(path, {
        headers: this.headers,
        observe: 'response',
        params: params,
      })
      .pipe(map((response) => response));
  }

  public post(path: string, payload?: any): Observable<HttpResponse<any>> {
    return this.http
      .post(path, payload, {
        headers: this.headers,
        observe: 'response',
      })
      .pipe(map((response) => response));
  }

  public put(path: string, payload: any): Observable<HttpResponse<any>> {
    return this.http
      .put(path, payload, {
        headers: this.headers,
        observe: 'response',
      })
      .pipe(map((response) => response));
  }

  public delete(path: string, payload: any): Observable<HttpResponse<any>> {
    return this.http
      .delete(path, {
        headers: this.headers,
        observe: 'response',
        body: payload
      })
      .pipe(map((response) => response));
  }
}
