import { ECaseStatus } from '../enums/case-status.enum';
import { ICaseTracking } from '../interfaces/case-tracking.interface';

export class CaseTracking implements ICaseTracking {
  id?: string;
  companySpName: string;
  runnerName: string;
  runnerVehicleNumber: string;
  runnerTrackingUrl: string;
  status: ECaseStatus;
  runnerTelephoneNumber: string;
  eta: number;
  etaText: string;
  etaToWorkshop: number;
  etaToWorkshopText: string;
  latitude: number;
  longitude: number;
  statusId: number;

  constructor(caseTracking: CaseTracking) {
    if (caseTracking?.id) {
      this.id = caseTracking.id;
    }

    this.companySpName = caseTracking?.companySpName || null;
    this.runnerName = caseTracking?.runnerName || null;
    this.runnerVehicleNumber = caseTracking?.runnerVehicleNumber || null;
    this.runnerTrackingUrl = caseTracking?.runnerTrackingUrl || null;
    this.status = caseTracking?.status || null;
    this.runnerTelephoneNumber = caseTracking?.runnerTelephoneNumber || null;
    this.eta = caseTracking?.eta || 0;
    this.etaText = caseTracking?.etaText || null;
    this.etaToWorkshop = caseTracking?.etaToWorkshop || 0;
    this.etaToWorkshopText = caseTracking?.etaToWorkshopText || null;
    this.latitude = caseTracking?.latitude || 0;
    this.longitude = caseTracking?.longitude || 0;
    this.statusId = caseTracking?.statusId || 0;
  }
}
