import { EUserStatus } from '@ata/shared/data-access/users';

export interface ICaseUser {
  id: string;
  username: string;
  email: string;
  provider: string;
  password: string;
  resetPasswordToken: string;
  confirmed: boolean;
  blocked: boolean;
  role: string;
  notifications: Array<string>;
  doneNotification: Array<string>;
  lastLogin: string;
  lastLogout: string;
  displayName: string;
  status: EUserStatus;
  currentAssignment: string;
  phoneNumber: 0;
  created_by: string;
  updated_by: string;
}
