import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { NotFoundComponent } from './features/not-found/not-found.component';
import { LayoutDefaultComponent } from './layout/components/layout-default/layout-default.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'help',
    pathMatch: 'full',
  },
  {
    path: '',
    component: LayoutDefaultComponent,
    children: [
      {
        path: 'landing',
        loadChildren: () => import('./features/landing/landing.module').then((m) => m.LandingModule),
      },
      {
        path: 'locate/:ticketId/:vehicleNo',
        loadChildren: () => import('./features/locate/locate.module').then((m) => m.LocateModule),
      },
    ]
  },
  {
    path: 'tracking',
    loadChildren: () => import('./features/tracking/tracking.module').then((m) => m.TrackingModule),
  },
  {
    path: 'help',
    loadChildren: () => import('./features/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'location',
    loadChildren: () => import('./features/location/location.module').then((m) => m.LocationModule),
  },
  {
    path: 'assessment',
    loadChildren: () => import('./features/assessment/assessment.module').then((m) => m.AssessmentModule),
  },
  {
    path: 'workshop',
    loadChildren: () => import('./features/workshop/workshop.module').then((m) => m.WorkshopModule),
  },
  {
    path: 'rating',
    loadChildren: () => import('./features/rating/rating.module').then((m) => m.RatingModule),
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload'
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
