import { ECaseStatus } from '../enums/case-status.enum';

export interface ICaseTracking {
  id?: string;
  companySpName: string;
  runnerName: string;
  runnerVehicleNumber: string;
  runnerTrackingUrl: string;
  status: ECaseStatus;
  runnerTelephoneNumber: string;
  eta: number;
  etaText: string;
  etaToWorkshop: number;
  etaToWorkshopText: string;
  latitude: number;
  longitude: number;
  statusId: number;
}
