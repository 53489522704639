import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import * as AuthActions from './auth.actions';
import * as AuthSelectors from './auth.selectors';
import { IRequestOTPDto } from '../dtos/request-otp.dto';
import { IVerifyOTPDto } from '../dtos/verify-otp.dto';
import { ILoginDto } from '../dtos/login.dto';

@Injectable()
export class AuthFacade {
  readonly otp$ = this.store.pipe(select(AuthSelectors.getAuthOTP));

  readonly jwt$ = this.store.pipe(select(AuthSelectors.getAuthJWT));

  readonly logIn$ = this.store.pipe(select(AuthSelectors.getAuthLogIn));

  readonly loggedIn$ = this.store.pipe(select(AuthSelectors.getAuthLoggedIn));

  readonly logOut$ = this.store.pipe(select(AuthSelectors.getAuthLogOut));

  readonly loggedOut$ = this.store.pipe(select(AuthSelectors.getAuthLoggedOut));

  readonly requestOTP$ = this.store.pipe(select(AuthSelectors.getAuthRequestOTP));

  readonly requestedOTP$ = this.store.pipe(select(AuthSelectors.getAuthRequestedOTP));

  readonly verifyOTP$ = this.store.pipe(select(AuthSelectors.getAuthVerifyOTP));

  readonly verifiedOTP$ = this.store.pipe(select(AuthSelectors.getAuthVerifiedOTP));

  readonly error$ = this.store.pipe(select(AuthSelectors.getAuthError));

  constructor(private readonly store: Store) {}

  readonly requestOTP = (payload: IRequestOTPDto) => {
    this.store.dispatch(AuthActions.getAuthOTPInit({ payload }));
  };

  readonly verifyOTP = (payload: IVerifyOTPDto) => {
    this.store.dispatch(AuthActions.verifyAuthOTPInit({ payload }));
  };

  readonly login = (payload: ILoginDto) => {
    this.store.dispatch(AuthActions.requestAuthLoginInit({ payload }));
  };

  readonly logout = () => {
    this.store.dispatch(AuthActions.requestAuthLogoutInit());
  };
}
