<div class="ata-flex ata-flex-col">
    <div class="ata-relative">
        <svg-icon
            class="ata-absolute ata-z-10 ata-top-2/4 -ata-translate-y-2/4 ata-left-4 ata-flex ata-items-center ata-text-lg"
            src="assets/svgs/icon-search.svg"
            ></svg-icon>
            <input
            #mapAutocomplete
            class="ata-input ata-pl-12"
            [class.ata-rounded-b-none]="getLocationButton || additionalInfo"
            type="text"
            [readOnly]="readonly"
            [placeholder]="placeholder"
            [(ngModel)]="value.name"
            />
            <i class="pi pi-times ata-absolute ata-z-10 ata-top-2/4 -ata-translate-y-2/4 ata-right-4 ata-flex ata-items-center ata-text-lg clear-input x-icon" *ngIf="manualInput" (click)="clearValue()"></i>
    </div>
    <button
        class="ata-flex ata-items-center ata-px-4 ata-py-2 ata-font-bold ata-border-x ata-border-b ata-bg-white ata-border-slate-300 ata-text-secondary-500"
        [ngClass]="{ 'ata-rounded-b-base': !additionalInfo }"
        *ngIf="getLocationButton"
        (click)="getCurrentLocation()"
    >
        <span class="ata-mr-auto">Or use my current location</span>
        <svg-icon
            class="ata-flex ata-items-center ata-text-lg"
            [src]="(isGeolocating$ | async) ? 'assets/svgs/loading-spinner.svg' : 'assets/svgs/location-current.svg'"
        ></svg-icon>
    </button>
    <textarea
        class="ata-input ata-rounded-t-none ata-border-t-0 -ata-mt-px"
        placeholder="Additional Info"
        rows="2"
        *ngIf="additionalInfo"
        (change)="onAdditionalInfoInputChange($event)"
        [(ngModel)]="value.additionalInfo"
    ></textarea>
</div>
