import { Component, forwardRef, Injector, Input } from '@angular/core';
import { ControlValueAccessor, NgControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'ata-input-rating',
  templateUrl: './input-rating.component.html',
  styleUrls: ['./input-rating.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputRatingComponent),
      multi: true
    }
  ]
})
export class InputRatingComponent implements ControlValueAccessor {
  public checked!: boolean;

  public model!: any;

  @Input() name = "";

  @Input() label = "";

  @Input() value = "";

  @Input() disabled = false;

  @Input() checkedIcon = 'assets/svgs/checkbox-circle-checked.svg';

  @Input() checkedColor = '#f15a22';

  @Input() uncheckedIcon = 'assets/svgs/checkbox-circle-blank.svg';

  @Input() uncheckedColor = '#c4c4c4';

  constructor(private _injector: Injector) { }

  onChange: any = () => {
    // empty for now
  }

  onTouch: any = () => {
    // empty for now
  }  

  onInputChange(val: any){ 
    this.checked = val == this.value;
    this.onChange(val);
    this._injector.get(NgControl)?.control?.setValue(val);
  }

  writeValue(value: any): void {  
    this.model = value;
    this.checked = value == this.value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
