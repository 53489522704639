<div class="flex align-items-center bg-pink-100 text-red-700 border-1 border-pink-200 border-round shadow-1 p-2 animate__bounceIn">
  <div class="flex align-items-center justify-content-center p-2 border-round bg-pink-200">
    <div class="flex align-items-center justify-content-center w-2rem h-2rem">
      <svg class="w-2rem h-2rem" viewBox="0 0 24 24">
        <path
          fill="currentColor"
          d="M11,15H13V17H11V15M11,7H13V13H11V7M12,2C6.47,2 2,6.5 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20Z"
        />
      </svg>
    </div>
  </div>
  <span class="capitalize ml-3 text-sm">{{ message }}</span>
</div>
