import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const noWhiteSpaceValidator = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const whiteSpace = /\s/.test(control.value);

    return whiteSpace ? { whiteSpace: true } : null;
  };
};


export const ticketNoValidator = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const ticketNo = /^(\d{4})-(\d{5})$/.test(control.value);

    return ticketNo ? null : { ticketNo: true };
  };
};