export interface ICaseNonRegisteredCustomer {
  id?: string;
  name: string;
  email: string;
  phoneNo: string;
  phoneNoCountryCode?: string;
  phoneNo2: string;
  phoneNo2CountryCode?: string;
  phoneNo3: string;
  phoneNo3CountryCode?: string;

  // custom prop
  abbreviation: string;
}
