import { APP_STATE_NAME } from "./apps.constants";

export class SetVehicleType {
  static readonly type = `[${APP_STATE_NAME}-atau] Set Vehicle Type`;

  constructor(public type: string) {}
}
  

export class SetLocation {
  static readonly type = `[${APP_STATE_NAME}-atau] Set Location`;

  constructor(public location: any) {}
}

export class SetDestination {
  static readonly type = `[${APP_STATE_NAME}-atau] Set Destination`;

  constructor(public destination: any) {}
}

export class SetWorkshop {
  static readonly type = `[${APP_STATE_NAME}-atau] Set Workshop`;

  constructor(public workshop: any) {}
}

export class SetService {
  static readonly type = `[${APP_STATE_NAME}-atau] Set Service`;

  constructor(public service: any) {}
}

export class SetFormData {
  static readonly type = `[${APP_STATE_NAME}-atau] Set Form Data`;

  constructor(public formData: any) {}
}

export class SetActiveStep {
  static readonly type = `[${APP_STATE_NAME}-atau] Set Active Step`;

  constructor(public index: number) {}
}

export class SetDestinationType {
  static readonly type = `[${APP_STATE_NAME}-atau] Set Destination Type`

  constructor(public type: string) {}
}

export class SetQuestionnaires {
  static readonly type = `[${APP_STATE_NAME}-atau] Set Questionnaires`

  constructor(public questionnaires: any) {}
}

export class SetOverridingService {
  static readonly type = `[${APP_STATE_NAME}-atau] Set Overrding Service`

  constructor(public overridingService: any) {}
}

export class SetDuplicateCase {
  static readonly type = `[${APP_STATE_NAME}-atau] Set Duplicate Case`

  constructor(public caseData: any) {}
}

export class SetRatingMeta {
  static readonly type = `[${APP_STATE_NAME}-atau] Set Rating Meta`

  constructor(public ratingMeta: any) {}
}

export class SetUTMSource {
  static readonly type = `[${APP_STATE_NAME}-atau] Set UTM Source`

  constructor(public utmSource: string) {}
}

export class SetHasShownServiceDelay {
  static readonly type =  `[${APP_STATE_NAME}-atau] Set Has Shown Service Delay`;
  constructor(public hasShownServiceDelay: boolean) {}
}