import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ECaseStatus, ECaseSubStatus } from '@ata/shared/data-access/cases';

@Component({
  selector: 'ata-case-status',
  templateUrl: './case-status.component.html',
  styleUrls: ['./case-status.component.scss'],
})
export class CaseStatusComponent implements OnChanges {
  public prettyStatus = {
    [ECaseStatus.NEW_REQUEST]: 'New Request',
    [ECaseStatus.OPEN]: 'Open',
    [ECaseStatus.ASSIGNED]: 'Assigned',
    [ECaseStatus.ACCEPTED]: 'Accepted',
    [ECaseStatus.ARRIVED_AT_CUSTOMER]: 'Arrived At Customer',
    [ECaseStatus.ARRIVED_AT_WORKSHOP]: 'Arrived At Workshop',
    [ECaseStatus.ARRIVED_AT_POLICE_STATION]: 'Arrived At Police Station',
    [ECaseStatus.TOWING_TO_WORKSHOP]: 'Towing To Workshop',
    [ECaseStatus.CANCELLED]: 'Cancelled',
    [ECaseStatus.RESOLVED]: 'Resolved',
    [ECaseStatus.CLOSED]: 'Closed',
    [ECaseStatus.ERROR]: 'Error',
    [ECaseSubStatus.PENDING_ACTION]: 'Pending Action',
    [ECaseSubStatus.PENDING_REVIEW]: 'Pending Review',
  };

  @Input() public status: ECaseStatus = ECaseStatus.NEW_REQUEST;

  @Input() public isAssignment = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isAssignment && changes.isAssignment.currentValue) {
      this.prettyStatus[ECaseStatus.ASSIGNED] = 'New case';
    }
  }
}
