import { Observable, of } from 'rxjs';

export class StateCityData {
  locationData: Array<IPlaceState> = [
    {
      id: 1,
      name: 'JOHOR',
      cities: [
        {
          id: 120,
          name: 'Ayer Hitam',
        },
        {
          id: 5,
          name: 'Batu Pahat',
        },
        {
          id: 67,
          name: 'Gelang Patah',
        },
        {
          id: 6,
          name: 'Johor Bahru',
        },
        {
          id: 112,
          name: 'Kluang',
        },
        {
          id: 3,
          name: 'Kota Tinggi',
        },
        {
          id: 66,
          name: 'Kulai',
        },
        {
          id: 119,
          name: 'Labis',
        },
        {
          id: 61,
          name: 'Masai',
        },
        {
          id: 121,
          name: 'Mersing',
        },
        {
          id: 65,
          name: 'Muar',
        },
        {
          id: 64,
          name: 'Pasir Gudang',
        },
        {
          id: 111,
          name: 'Pekan Nanas',
        },
        {
          id: 115,
          name: 'Pengerang',
        },
        {
          id: 4,
          name: 'Pontian',
        },
        {
          id: 117,
          name: 'Segamat',
        },
        {
          id: 2,
          name: 'Senai',
        },
        {
          id: 116,
          name: 'Skudai',
        },
        {
          id: 62,
          name: 'Tampoi',
        },
        {
          id: 118,
          name: 'Tangkak',
        },
        {
          id: 63,
          name: 'Ulu Tiram',
        },
        {
          id: 122,
          name: 'Yong Peng',
        },
      ],
    },
    {
      id: 2,
      name: 'KEDAH',
      cities: [
        {
          id: 9,
          name: 'Alor Setar',
        },
        {
          id: 8,
          name: 'Baling',
        },
        {
          id: 124,
          name: 'Bedong',
        },
        {
          id: 68,
          name: 'Gurun',
        },
        {
          id: 69,
          name: 'Jitra',
        },
        {
          id: 72,
          name: 'Kuala Kedah',
        },
        {
          id: 74,
          name: 'Kulim',
        },
        {
          id: 125,
          name: 'Kupang',
        },
        {
          id: 73,
          name: 'Langkawi',
        },
        {
          id: 123,
          name: 'Lunas',
        },
        {
          id: 71,
          name: 'Padang Serai',
        },
        {
          id: 70,
          name: 'Pendang',
        },
        {
          id: 7,
          name: 'Sungai Petani',
        },
      ],
    },
    {
      id: 3,
      name: 'KELANTAN',
      cities: [
        {
          id: 11,
          name: 'Gua Musang',
        },
        {
          id: 77,
          name: 'Ketereh',
        },
        {
          id: 12,
          name: 'Kota Bharu',
        },
        {
          id: 75,
          name: 'Kuala Krai',
        },
        {
          id: 10,
          name: 'Machang',
        },
        {
          id: 76,
          name: 'Tanah Merah',
        },
      ],
    },
    {
      id: 4,
      name: 'MELAKA',
      cities: [
        {
          id: 82,
          name: 'Alor Gajah',
        },
        {
          id: 81,
          name: 'Ayer Keroh',
        },
        {
          id: 80,
          name: 'Ayer Molek',
        },
        {
          id: 79,
          name: 'Bukit Katil',
        },
        {
          id: 78,
          name: 'Jasin',
        },
        {
          id: 13,
          name: 'Melaka',
        },
      ],
    },
    {
      id: 5,
      name: 'NEGERI SEMBILAN',
      cities: [
        {
          id: 86,
          name: 'Bahau',
        },
        {
          id: 85,
          name: 'Bandar Baru Enstek',
        },
        {
          id: 130,
          name: 'Kuala Pilah',
        },
        {
          id: 127,
          name: 'Labu',
        },
        {
          id: 129,
          name: 'Mantin',
        },
        {
          id: 84,
          name: 'Nilai',
        },
        {
          id: 87,
          name: 'Pedas',
        },
        {
          id: 131,
          name: 'Port Dickson',
        },
        {
          id: 83,
          name: 'Senawang',
        },
        {
          id: 14,
          name: 'Seremban',
        },
        {
          id: 128,
          name: 'Tampin',
        },
      ],
    },
    {
      id: 6,
      name: 'PAHANG',
      cities: [
        {
          id: 96,
          name: 'Bentong',
        },
        {
          id: 98,
          name: 'Brincang',
        },
        {
          id: 99,
          name: 'Jerantut',
        },
        {
          id: 132,
          name: 'Kuala Lipis',
        },
        {
          id: 16,
          name: 'Kuantan',
        },
        {
          id: 17,
          name: 'Maran',
        },
        {
          id: 18,
          name: 'Mentakab',
        },
        {
          id: 172,
          name: 'Raub',
        },
        {
          id: 97,
          name: 'Temerloh',
        },
        {
          id: 15,
          name: 'Triang',
        },
      ],
    },
    {
      id: 7,
      name: 'PERAK',
      cities: [
        {
          id: 103,
          name: 'Ayer Tawar',
        },
        {
          id: 147,
          name: 'Bidor',
        },
        {
          id: 104,
          name: 'Gerik',
        },
        {
          id: 138,
          name: 'Gopeng',
        },
        {
          id: 140,
          name: 'Hutan Melintang',
        },
        {
          id: 20,
          name: 'Ipoh',
        },
        {
          id: 101,
          name: 'Kampar',
        },
        {
          id: 22,
          name: 'Kamunting',
        },
        {
          id: 106,
          name: 'Kemunting',
        },
        {
          id: 100,
          name: 'Kuala Kangsar',
        },
        {
          id: 21,
          name: 'Lahat',
        },
        {
          id: 139,
          name: 'Langkap',
        },
        {
          id: 141,
          name: 'Menglembu',
        },
        {
          id: 146,
          name: 'Pusing',
        },
        {
          id: 105,
          name: 'Siputeh',
        },
        {
          id: 23,
          name: 'Sitiawan',
        },
        {
          id: 142,
          name: 'Slim River',
        },
        {
          id: 143,
          name: 'Sungkai',
        },
        {
          id: 102,
          name: 'Taiping',
        },
        {
          id: 144,
          name: 'Tanjong Malim',
        },
        {
          id: 145,
          name: 'Tapah',
        },
        {
          id: 19,
          name: 'Teluk Intan',
        },
      ],
    },
    {
      id: 9,
      name: 'PERLIS',
      cities: [
        {
          id: 27,
          name: 'Arau',
        },
        {
          id: 26,
          name: 'Kangar',
        },
        {
          id: 94,
          name: 'Kodiang',
        },
        {
          id: 95,
          name: 'Padang Besar',
        },
      ],
    },
    {
      id: 8,
      name: 'PENANG (ISLAND)', //'PULAU PINANG (ISLAND)',
      cities: [
        {
          id: 133,
          name: 'Batu Maung',
        },
        {
          id: 25,
          name: 'Bayan Lepas',
        },
        {
          id: 59,
          name: 'Gelugor',
        },
        {
          id: 57,
          name: 'George Town',
        },
        {
          id: 58,
          name: 'Jelutong',
        },
        {
          id: 24,
          name: 'Pulau Pinang',
        },
        {
          id: 56,
          name: 'Teluk Kumbar',
        },
      ],
    },
    {
      id: 17,
      name: 'PENANG (SEBERANG PERAI)', //'PULAU PINANG (MAINLAND)',
      cities: [
        {
          id: 48,
          name: 'Bukit Mertajam',
        },
        {
          id: 113,
          name: 'Butterworth',
        },
        {
          id: 49,
          name: 'Kepala Batas',
        },
        {
          id: 134,
          name: 'Nibong Tebal',
        },
        {
          id: 171,
          name: 'Perai',
        },
        {
          id: 136,
          name: 'Permatang Pauh',
        },
        {
          id: 137,
          name: 'Simpang Ampat',
        },
        {
          id: 135,
          name: 'Sungai Jawi',
        },
      ],
    },
    {
      id: 10,
      name: 'SABAH',
      cities: [
        {
          id: 151,
          name: 'Beaufort',
        },
        {
          id: 92,
          name: 'Inaman',
        },
        {
          id: 153,
          name: 'Keningau',
        },
        {
          id: 50,
          name: 'Kota Kinabalu',
        },
        {
          id: 110,
          name: 'Kota Marudu',
        },
        {
          id: 148,
          name: 'Labuan',
        },
        {
          id: 31,
          name: 'Lahad Datu',
        },
        {
          id: 152,
          name: 'Membakut',
        },
        {
          id: 150,
          name: 'Papar',
        },
        {
          id: 91,
          name: 'Penampang',
        },
        {
          id: 93,
          name: 'Putatan',
        },
        {
          id: 30,
          name: 'Ranau',
        },
        {
          id: 60,
          name: 'Sandakan',
        },
        {
          id: 29,
          name: 'Semporna',
        },
        {
          id: 28,
          name: 'Tawau',
        },
        {
          id: 149,
          name: 'Tuaran',
        },
      ],
    },
    {
      id: 12,
      name: 'SARAWAK',
      cities: [
        {
          id: 155,
          name: 'Bintangor',
        },
        {
          id: 41,
          name: 'Bintulu',
        },
        {
          id: 90,
          name: 'Kota Samarahan',
        },
        {
          id: 40,
          name: 'Kuching',
        },
        {
          id: 39,
          name: 'Limbang',
        },
        {
          id: 170,
          name: 'Miri',
        },
        {
          id: 157,
          name: 'Mukah',
        },
        {
          id: 154,
          name: 'Sarikei',
        },
        {
          id: 114,
          name: 'Sibu',
        },
        {
          id: 156,
          name: 'Sri Aman',
        },
      ],
    },
    {
      id: 11,
      name: 'SELANGOR',
      cities: [
        {
          id: 38,
          name: 'Ampang',
        },
        {
          id: 52,
          name: 'Ampang Jaya',
        },
        {
          id: 164,
          name: 'Balakong',
        },
        {
          id: 108,
          name: 'Bandar Baru Bangi',
        },
        {
          id: 159,
          name: 'Banting',
        },
        {
          id: 109,
          name: 'Batang Kali',
        },
        {
          id: 37,
          name: 'Batu Caves',
        },
        {
          id: 166,
          name: 'Cheras',
        },
        {
          id: 169,
          name: 'Hulu Langat',
        },
        {
          id: 162,
          name: 'Jenjarom',
        },
        {
          id: 158,
          name: 'Jeram',
        },
        {
          id: 89,
          name: 'Kajang',
        },
        {
          id: 53,
          name: 'Klang',
        },
        {
          id: 35,
          name: 'Pelabuhan Klang',
        },
        {
          id: 54,
          name: 'Petaling Jaya',
        },
        {
          id: 33,
          name: 'Puchong',
        },
        {
          id: 55,
          name: 'Rawang',
        },
        {
          id: 34,
          name: 'Selayang',
        },
        {
          id: 165,
          name: 'Semenyih',
        },
        {
          id: 160,
          name: 'Serendah',
        },
        {
          id: 51,
          name: 'Seri Kembangan',
        },
        {
          id: 32,
          name: 'Shah Alam',
        },
        {
          id: 36,
          name: 'Subang Jaya',
        },
        {
          id: 161,
          name: 'Sungai Besar',
        },
        {
          id: 107,
          name: 'Sungai Buloh',
        },
        {
          id: 163,
          name: 'Tanjong Karang',
        },
      ],
    },
    {
      id: 13,
      name: 'TERENGGANU',
      cities: [
        {
          id: 88,
          name: 'Chukai',
        },
        {
          id: 44,
          name: 'Dungun',
        },
        {
          id: 167,
          name: 'Jerteh',
        },
        {
          id: 42,
          name: 'Kemaman',
        },
        {
          id: 45,
          name: 'Kuala Terengganu',
        },
        {
          id: 43,
          name: 'Marang',
        },
      ],
    },
    {
      id: 14,
      name: 'WILAYAH PERSEKUTUAN',
      cities: [
        {
          id: 46,
          name: 'Kuala Lumpur',
        },
      ],
    },
    {
      id: 15,
      name: '-- N/A --',
      cities: [
        {
          id: 47,
          name: '-- N/A --',
        },
      ],
    },
  ];

  getLocationData(): Observable<Array<IPlaceState>> {
    return of(this.locationData);
  }

  getStateCityData() {
    return this.locationData;
  }
}

export interface IPlaceCity {
  id: number;
  name: string;
}

export interface IPlaceState {
  id: number;
  name: string;
  cities: Array<IPlaceCity>;
}
