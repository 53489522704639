import { ICaseActivityLog } from '../interfaces/case-activity-log.interface';

export class CaseActivityLog implements ICaseActivityLog {
  id?: string;
  activity: string;
  createdBy: string;
  remark: string;
  createdDate: Date;
  status?: string;
  provider?: string;

  constructor(caseActivityLog: CaseActivityLog) {
    if (caseActivityLog?.id) {
      this.id = caseActivityLog.id;
    }

    this.activity = caseActivityLog?.activity || null;
    this.createdBy = caseActivityLog?.createdBy || null;
    this.remark = caseActivityLog?.remark || null;
    this.createdDate = caseActivityLog?.createdDate || null;
    this.status = caseActivityLog?.status || null;
    this.provider = caseActivityLog?.provider || null;
  }
}
