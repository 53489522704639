import { Injectable, Optional } from '@angular/core';

import { IConfig } from '../interfaces/config.interface';
import { Config } from '../models/config.model';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private static _config: IConfig;

  constructor(@Optional() config: Config) {
    if (config) {
      ConfigService._config = config;
    }
  }


  get config(): IConfig {
    return new Config(ConfigService._config);
  }
}
