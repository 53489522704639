import { ICaseAssignee, ICaseAssigneeRole, ICaseAssigneeSkills } from '../interfaces/case-assignee.interface';
class CaseAssigneeRole implements ICaseAssigneeRole {
  id: string;
  name: string;
  type: string;

  constructor(role: CaseAssigneeRole) {
    this.id = role?.id;
    this.name = role?.name;
    this.type = role?.type;
  }
}

class CaseAssigneeSkills implements ICaseAssigneeSkills {
  handbrakeRepair: boolean;
  gearRepair: boolean;
  steeringRepair: boolean;

  constructor(skill: CaseAssigneeSkills) {
    this.handbrakeRepair = skill?.handbrakeRepair;
    this.gearRepair = skill?.gearRepair;
    this.steeringRepair = skill?.steeringRepair;
  }
}

export class CaseAssignee implements ICaseAssignee {
  id: string;
  username: string;
  displayName: string;
  email: string;
  role: ICaseAssigneeRole;
  status: string;
  riderVehicleNumber: string;
  svcOfferedAccident: boolean;
  svcOfferedBreakdown: boolean;
  svcOfferedRos: boolean;
  svcOfferedMotorcycleTowing: boolean;
  skills: ICaseAssigneeSkills;
  distance: string;

  constructor(assignee: CaseAssignee) {
    this.id = assignee?.id;
    this.username = assignee?.username;
    this.displayName = assignee?.displayName;
    this.email = assignee?.email;
    this.role = new CaseAssigneeRole(assignee?.role);
    this.status = assignee?.status;
    this.riderVehicleNumber = assignee?.riderVehicleNumber;
    this.svcOfferedAccident = assignee?.svcOfferedAccident;
    this.svcOfferedBreakdown = assignee?.svcOfferedBreakdown;
    this.svcOfferedRos = assignee?.svcOfferedRos;
    this.svcOfferedMotorcycleTowing = assignee?.svcOfferedMotorcycleTowing;
    this.skills = new CaseAssigneeSkills(assignee?.skills);
    this.distance = assignee?.distance;
  }
}
