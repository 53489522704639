import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { AngularSvgIconModule } from 'angular-svg-icon';
import { PrimeNgModule } from './primeng.module';

import { CountryCodeComponent } from './components/country-code/country-code.component';
import { AtaAlertComponent } from './components/ata-alert/ata-alert.component';
import { CaseStatusComponent } from './components/case-status/case-status.component';
import { InputPhoneComponent } from './components/input-phone/input-phone.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { PageErrorNotfoundComponent } from './components/page-error-notfound/page-error-notfound.component';
import { InputPlaceAutocompleteComponent } from './components/input-place-autocomplete/input-place-autocomplete.component';
import { JumbotronComponent } from './components/jumbotron/jumbotron.component';
import { CardComponent } from './components/card/card.component';
import { CheckboxBoxComponent } from './components/checkbox-box/checkbox-box.component';
import { NavItemComponent } from './components/nav-item/nav-item.component';
import { StepperComponent } from './components/stepper/stepper.component';
import { InputCheckboxComponent } from './components/input-checkbox/input-checkbox.component';
import { InputRadioComponent } from './components/input-radio/input-radio.component';
import { InputRatingComponent } from './components/input-rating/input-rating.component';
import { LoadingBlockComponent } from './components/loading-block/loading-block.component';
import { AtaInfoPopupComponent } from './components/ata-info-popup/ata-info-popup..component';
import { AtaAlertPopupComponent } from './components/ata-alert-popup/ata-alert-popup..component';

@NgModule({
  imports: [CommonModule, FormsModule, PrimeNgModule, AngularSvgIconModule.forRoot()],
  declarations: [
    CountryCodeComponent,
    AtaAlertComponent,
    CaseStatusComponent,
    InputPhoneComponent,
    InputPlaceAutocompleteComponent,
    InputCheckboxComponent,
    InputRadioComponent,
    InputRatingComponent,
    PageHeaderComponent,
    PageErrorNotfoundComponent,
    JumbotronComponent,
    CardComponent,
    CheckboxBoxComponent,
    NavItemComponent,
    StepperComponent,
    LoadingBlockComponent,
    AtaInfoPopupComponent,
    AtaAlertPopupComponent,
  ],
  exports: [
    CountryCodeComponent,
    AtaAlertComponent,
    CaseStatusComponent,
    InputPhoneComponent,
    InputPlaceAutocompleteComponent,
    InputCheckboxComponent,
    InputRadioComponent,
    InputRatingComponent,
    PageHeaderComponent,
    PageErrorNotfoundComponent,
    JumbotronComponent,
    CardComponent,
    CheckboxBoxComponent,
    NavItemComponent,
    AngularSvgIconModule,
    StepperComponent,
    LoadingBlockComponent,
    AtaInfoPopupComponent,
    AtaAlertPopupComponent,
  ],
})
export class CustomUiModule {}
