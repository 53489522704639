import { Component } from '@angular/core';

@Component({
  selector: 'ata-country-code',
  templateUrl: './country-code.component.html',
  styleUrls: ['./country-code.component.scss'],
})
export class CountryCodeComponent {
  public countryCode: Array<{ label: string; value: string }> = [
    {
      label: '+60',
      value: '60',
    },
    {
      label: '+65',
      value: '65',
    },
    {
      label: '+66',
      value: '66',
    },
    {
      label: '+673',
      value: '673',
    },
  ];
}
